import React, { useContext, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// Internal components
import BodyContent from "components/BodyContent";
import AnimateMount from "components/AnimateMount";
import ScrollTop from "components/ScrollTop";
import FadeIn from "react-fade-in";

// External components
import { Container, Row, Col } from "react-bootstrap";
// import { usePalette } from "react-palette";

const Project = () => {
    const { slug } = useParams();
    const { pathname } = useLocation();
    const { venues, setDevData, devData, data } = useContext(UmbracoContext);
    const [currentProject, setCurrentProject] = useState(null);
    // const [imgPalette, setImgPalette] = useState("");
    // const { data } = usePalette(imgPalette);

    useEffect(() => {
        if (
            currentProject === null &&
            venues !== null &&
            venues !== undefined
        ) {
            let current = venues.filter((p) => p.slug.toLowerCase() === slug);
            setCurrentProject(current[0]);
            // if (
            //     current[0] !== null &&
            //     current[0] !== undefined &&
            //     current[0].featuredImage
            // ) {
            //     setImgPalette(current[0].featuredImage.url);
            // }
        }
    }, [currentProject, slug, setCurrentProject, venues, pathname]);
    useEffect(() => {
        if (devData !== currentProject) {
            setDevData(currentProject);
        }
    }, [currentProject, setDevData, devData]);
    return (
        <>
            {currentProject !== null && currentProject !== undefined && (
                <>
                    <Helmet>
                        <title>
                            {currentProject.seo?.title || currentProject.title}
                        </title>

                        <meta
                            property="og:title"
                            content={
                                currentProject.seo?.title ||
                                currentProject.title
                            }
                        />
                        <meta
                            name="twitter:title"
                            content={
                                currentProject.seo?.title ||
                                currentProject.title
                            }
                        />
                        <meta
                            property="og:image"
                            content={
                                currentProject.seo?.featuredImage?.url ||
                                currentProject.featuredImage?.url
                            }
                        />
                        <meta
                            name="twitter:image"
                            content={
                                currentProject.seo?.featuredImage?.url ||
                                currentProject.featuredImage?.url
                            }
                        />
                        <meta
                            property="og:description"
                            content={currentProject.seo?.description}
                        />
                        <meta
                            name="twitter:description"
                            content={currentProject.seo?.description}
                        />
                        <meta
                            name="description"
                            content={currentProject.seo?.description}
                        />
                    </Helmet>
                    <AnimateMount trigger>
                        <Container above-cubes className="venue">
                            <FadeIn>
                                <Row className="px-md-4 pt-5 mt-5 px-4 px-md-0">
                                    <Col xs={12} lg={6}>
                                        {currentProject.title && (
                                            <div className="w-100">
                                                <h1>{currentProject.title}</h1>
                                            </div>
                                        )}
                                        {currentProject.description && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: currentProject.description,
                                                }}
                                                className="paragraph"
                                            ></div>
                                        )}
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <AnimateMount
                                            trigger={
                                                currentProject.featuredImage !==
                                                null
                                            }
                                        >
                                            <img
                                                src={
                                                    currentProject
                                                        ?.featuredImage?.url +
                                                    "&format=jpeg&width=1200&quality=65"
                                                }
                                                alt={
                                                    currentProject
                                                        ?.featuredImage
                                                        ?.altText ||
                                                    currentProject
                                                        ?.featuredImage?.title
                                                }
                                                style={{
                                                    maxWidth: 800,
                                                    minHeight: 400,
                                                    objectFit: "cover",
                                                }}
                                                className="w-100 h100 rounded mb-5"
                                            />
                                        </AnimateMount>
                                    </Col>
                                </Row>
                            </FadeIn>
                            <div
                                className="shadow p-3 py-4 p-md-4 p-md-5 above-cubes"
                                style={{
                                    backgroundColor: data.colors.bg,
                                }}
                            >
                                <FadeIn>
                                    {currentProject.bodyContent && (
                                        <BodyContent
                                            content={currentProject.bodyContent}
                                        />
                                    )}
                                    <div className="d-flex justify-content-end">
                                        <ScrollTop />
                                    </div>
                                </FadeIn>
                            </div>
                        </Container>
                    </AnimateMount>
                </>
            )}
        </>
    );
};

export default Project;
