import React, { useContext, useState, useEffect } from "react";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// Internal components
import Gallery from "components/Gallery";
import UmbImage from "./UmbImage";

const BodyContent = ({ content }) => {
    const { data } = useContext(UmbracoContext);
    const [contentArray, setContentArray] = useState([]);
    useEffect(() => {
        if (content !== null) {
            if (Array.isArray(content)) {
                setContentArray(content);
                // console.log(content);
            } else if (typeof content === "object") {
                setContentArray([content]);
            }
        }
    }, [content]);
    return (
        <>
            {contentArray &&
                contentArray.map((body, index) => (
                    <>
                        {/* <h1>{index}</h1> */}
                        <div
                            className={`article ${body.orientation
                                ? "article--" +
                                body.orientation.toLowerCase()
                                : ""
                                }`}
                        >
                            {body.image && (
                                <>

                                    <figure style={{ maxWidth: 1200, margin: "auto" }}>
                                        <UmbImage
                                            imgObj={body.image}
                                            width={1200}
                                            height={800}
                                            alt={
                                                body.image.altText ||
                                                body.image.title
                                            }
                                            params="&mode=crop"
                                            responsive={
                                                {
                                                    600: {
                                                        width: 600,
                                                        height: 400
                                                    },
                                                    800: {
                                                        width: 800,
                                                        height: 533
                                                    },
                                                    1000: {
                                                        width: 1000,
                                                        height: 667
                                                    },
                                                }
                                            }
                                        />
                                        <figcaption style={{ marginTop: ".5rem" }}>
                                            {body.image.altText}
                                        </figcaption>
                                    </figure>
                                    {/* <figure>
                                        <img
                                            key={body.key + "image"}
                                            src={
                                                body.image.url +
                                                "?format=jpeg&width=1200&quality=65"
                                            }
                                            className="w-100 rounded mt-3 mb-4"
                                            alt={
                                                body.image.altText ||
                                                body.image.title
                                            }
                                        />
                                        <figcaption>
                                            {body.image.altText}
                                        </figcaption>
                                    </figure> */}
                                </>
                            )}
                            {body.textContent && (
                                <div
                                    className={` article__content
                                    mb-5`}
                                    key={body.key + "textcontent"}
                                    style={{
                                        color: "rgba(255,255,255,0.8)"
                                        // color: data.colors.color2,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: body.textContent,
                                    }}
                                ></div>
                            )}
                        </div>
                        {body.images !== null &&
                            Array.isArray(body.images) &&
                            body.images.length && (
                                <div className="my-5">
                                    <h1>Galleri</h1>
                                    <Gallery items={body.images} />
                                </div>
                            )}
                    </>
                ))}
        </>
    );
};

export default BodyContent;
