import React, { useContext, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

// External components
import { Container } from "react-bootstrap";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// Internal components
import Slider from "components/Slider";
import Date from "components/Date";
import BodyContent from "components/BodyContent";
import BreadCrumbs from "components/BreadCrumbs";
import Posts from "components/Posts";
import ScrollTop from "components/ScrollTop";
import AnimateMount from "components/AnimateMount";
import UmbImage from "components/UmbImage";

const Article = () => {
    const { slug } = useParams();
    const location = useLocation();
    const { posts, data } = useContext(UmbracoContext);
    const [currentPost, setCurrentPost] = useState(null);
    const [currentColor, setCurrentColor] = useState(null);

    useEffect(() => {
        if (currentPost === null && posts !== null) {
            let current = posts.filter(
                (post) =>
                    post.slug.replace(/\/aktuelt\/([^/]*)\//g, "$1") === slug
            );
            setCurrentPost(current[0]);
            // console.log(current[0]);
        }
    }, [currentPost, slug, setCurrentPost, posts]);

    useEffect(() => {
        if (currentPost !== null && currentPost !== undefined) {
            if (currentPost.category) {
                let current = data.indexPages
                    .filter(
                        (p) =>
                            p.title.toLowerCase().replace(" ", "_") ===
                            currentPost.category
                    )
                    .map((item) => item.mainColor);
                setCurrentColor(current[0]);
            }
        }
    }, [currentPost, setCurrentColor, data]);
    return (
        <>
            {currentPost !== null &&
                currentPost !== undefined &&
                currentPost !== undefined && (
                    <>
                        <Helmet>
                            <title>
                                {currentPost.seo.title || currentPost.title}
                            </title>

                            <meta
                                property="og:title"
                                content={
                                    currentPost.seo.title || currentPost.title
                                }
                            />
                            <meta
                                name="twitter:title"
                                content={
                                    currentPost.seo.title || currentPost.title
                                }
                            />

                            <meta
                                property="og:image"
                                content={
                                    currentPost.seo.featuredImage.url ||
                                    currentPost.featuredImage.url
                                }
                            />
                            <meta
                                name="twitter:image"
                                content={
                                    currentPost.seo.featuredImage.url ||
                                    currentPost.featuredImage.url
                                }
                            />
                            <meta
                                property="og:description"
                                content={currentPost.seo.description}
                            />
                            <meta
                                name="twitter:description"
                                content={currentPost.seo.description}
                            />
                            <meta
                                name="description"
                                content={currentPost.seo.description}
                            />
                        </Helmet>
                        <AnimateMount trigger>
                            <Container className="above-cubes articlePage">
                                <>

                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <div className="px-3">
                                                {currentPost.slug && (
                                                    <div
                                                        style={{
                                                            color: currentColor,
                                                        }}
                                                    >
                                                        <BreadCrumbs
                                                            crumbs={[
                                                                {
                                                                    to:
                                                                        "/" +
                                                                        currentPost.slug.split(
                                                                            "/"
                                                                        )[1],
                                                                    title: currentPost.slug.split(
                                                                        "/"
                                                                    )[1],
                                                                },
                                                                {
                                                                    to: location.pathname,
                                                                    title:
                                                                        currentPost.bodyTitle ||
                                                                        currentPost.title,
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                )}
                                                <h1 className="mb-2 article__title">
                                                    {currentPost.bodyTitle ||
                                                        currentPost.title}
                                                </h1>
                                            </div>
                                            {currentPost.publishDate && (
                                                <div className="mb-5 px-3">
                                                    <Date
                                                        date={currentPost.publishDate}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {/* <div className="col-12 col-lg-6 px-lg-5 mb-5">
                                            <figure>
                                                <UmbImage
                                                    imgObj={
                                                        currentPost.seo.featuredImage ||
                                                        currentPost.featuredImage}
                                                    width={1200}
                                                    height={800}
                                                />
                                            </figure>
                                        </div> */}
                                    </div>
                                    {currentPost.images !== null &&
                                        currentPost.images !== undefined && (
                                            <div className="my-5">
                                                <Slider
                                                    mainColor={currentColor}
                                                    arrows={
                                                        currentPost.images
                                                            .length > 1
                                                    }
                                                >
                                                    {currentPost.images.map(
                                                        (image) => (
                                                            <img
                                                                src={
                                                                    image.url +
                                                                    "?width=1200&format=jpeg&quality=65&sharpen(50,50,50)"
                                                                }
                                                                alt={
                                                                    image.altText ||
                                                                    image.title
                                                                }
                                                                key={
                                                                    image.id +
                                                                    image.title
                                                                }
                                                                className="w-100 rounded"
                                                                draggable={
                                                                    false
                                                                }
                                                                style={{
                                                                    maxHeight: 600,
                                                                }}
                                                            />
                                                        )
                                                    )}
                                                </Slider>
                                            </div>
                                        )}
                                    <div
                                        className="shadow p-3 py-4 p-md-4 p-md-5"
                                        style={{
                                            backgroundColor: data.colors.bg,
                                        }}
                                    >
                                        {currentPost.introText && (
                                            <div className="article__ingress"
                                                // className="h2"
                                                style={{
                                                    color: data.colors.color1,
                                                    // maxWidth: "50ch",
                                                    // paddingBottom: "100px",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html: currentPost.introText,
                                                }}
                                            ></div>
                                        )}
                                        <BodyContent
                                            content={currentPost.bodyContent}
                                        />
                                        {/* <p style={{ color: currentColor }}>
                                            mainColor = {currentColor}
                                        </p> */}
                                        {/* <p>
                                            {Object.entries(currentPost).map(
                                                ([key, val]) => (
                                                    <div
                                                        className="mb-4 above-cubes"
                                                        key={
                                                            currentPost.key +
                                                            key +
                                                            "entires"
                                                        }
                                                    >
                                                        {JSON.stringify(key)}:{" "}
                                                        {JSON.stringify(val)}
                                                    </div>
                                                )
                                            )}
                                        </p> */}
                                        <div className="d-flex justify-content-end">
                                            <ScrollTop
                                                mainColor={currentColor}
                                            />
                                        </div>
                                    </div>
                                </>
                                <div className="mt-5 pt-5">
                                    <Posts
                                        items={posts.filter(
                                            (p) => p.slug !== currentPost.slug
                                        )}
                                        limit={15}
                                    />
                                </div>
                            </Container>
                        </AnimateMount>
                    </>
                )}
        </>
    );
};

export default Article;
