import React from "react";
import { Link } from "react-router-dom";

const InternalOrExternalLink = ({
    children,
    to,
    target,
    rel,
    routePrefix = "",
    style,
    className,
}) => {
    if (to.startsWith("/")) {
        return (
            <Link
                to={routePrefix ? routePrefix + to : to}
                className={className}
                style={style}
            >
                {children}
            </Link>
        );
    } else {
        return (
            <a href={to} rel="noreferrer" style={style} className={className} target="_blank">
                {children}
            </a>
        );
    }
};

export default InternalOrExternalLink;
