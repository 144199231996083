import React, { useEffect } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ColorBox = ({
    h1,
    bgColor = "transparent",
    textColor = "white",
    rotated = false,
    children,
    p,
    to,
    current,
    className,
    id,
    iconLeft,
    iconRight,
    arrow = true,
    small,
}) => {
    useEffect(() => {
        if (to === current) {
            document
                .querySelectorAll(".colorbox")
                .forEach(
                    (box) =>
                        box !== null &&
                        box.classList.add("colorbox-not-current")
                );
            to &&
                document.getElementById(to) !== null &&
                document.getElementById(to).classList.add("colorbox-current");
        } else if (to !== current) {
            document
                .querySelectorAll(".colorbox")
                .forEach((box) => box.classList.remove("colorbox-not-current"));
        }
    }, [current, to]);
    // const [height, setHeight] = useState(null);
    // useEffect(() => {
    //     let div = document.getElementById(to);
    //     if (div !== null) {
    //         setHeight(div.offsetWidth);
    //     }
    // }, []);
    return (
        <>
            <Link
                onMouseOver={() =>
                    document
                        .querySelectorAll(".colorbox")
                        .forEach((box) => box.classList.add("colorbox-hover"))
                }
                id={id}
                onMouseLeave={() =>
                    document
                        .querySelectorAll(".colorbox")
                        .forEach((box) =>
                            box.classList.remove("colorbox-hover")
                        )
                }
                to={to}
                style={{
                    background: bgColor,
                    color: textColor,
                    // height: height,
                }}
                className={`colorbox ${small ? "colorbox-small" : ""}
                    ${rotated ? "colorbox-rotated" : ""}
                    ${className || ""}
                `}
            >
                <div>
                    {iconLeft}
                    {h1 && (
                        <h1>
                            {h1.split(" ").map((word, index) => (
                                <span
                                    className="d-block"
                                    key={
                                        Math.random() +
                                        index.toString() +
                                        "word"
                                    }
                                >
                                    {word}
                                </span>
                            ))}
                        </h1>
                    )}
                    {arrow && <FaAngleRight className="colorbox_icon" />}
                    {p && (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: p,
                            }}
                        ></p>
                    )}
                    {children}
                    {iconRight}
                </div>
            </Link>
        </>
    );
};

export default ColorBox;
