import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

// Internal components
import BodyContent from "components/BodyContent";
import Projects from "components/Projects";

// External components
import { Container, Row, Col } from "react-bootstrap";
import Helmet from "react-helmet";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const Category = () => {
    const { getIcon, allCategories, projectsAndVenues } = useContext(
        UmbracoContext
    );
    const { slug } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [category, setCategory] = useState({});
    useEffect(() => {
        if (slug && !loaded && projectsAndVenues) {
            let current = allCategories.filter(
                (c) => c.title.toLowerCase() === slug
            );
            setCategory(current[0]);
            // console.log(current[0]);
            // console.log(filtered);
            // console.log(slug);
            let filtered = projectsAndVenues.filter(
                (p) =>
                    p.categories !== null &&
                    JSON.stringify(p.categories).toLowerCase().includes(slug)
            );
            setFilteredProjects(filtered);
            setLoaded(true);
        }
    }, [slug, loaded, projectsAndVenues, allCategories]);
    return (
        <>
            {category && (
                <>
                    <Helmet>
                        <title>{category.title || category.seoTitle}</title>
                        <meta
                            property="og:title"
                            content={category.title || category.seoTitle}
                        />
                        <meta
                            name="twitter:title"
                            content={category.title || category.seoTitle}
                        />
                        <meta
                            property="og:image"
                            content={category.seoImage?.url}
                        />
                        <meta
                            name="twitter:image"
                            content={category.seoImage?.url}
                        />
                        <meta
                            property="og:description"
                            content={category.seoDescription}
                        />
                        <meta
                            name="twitter:description"
                            content={category.seoDescription}
                        />
                        <meta
                            name="description"
                            content={category.seoDescription}
                        />
                    </Helmet>
                    <Container>
                        <Row className=" px-md-4">
                            <Col xs={12} lg={6} className="pt-5 mt-5">
                                <h1
                                    className="d-flex align-items-center"
                                    style={{ gap: "2rem" }}
                                >
                                    {getIcon(
                                        category.title,
                                        `${category.mainColor}`
                                    )}
                                    <span>
                                        {category.bodyTitle || category.title}
                                    </span>
                                </h1>
                                {category.bodyDescription && (
                                    <div
                                        className="h2 "
                                        dangerouslySetInnerHTML={{
                                            __html: category.bodyDescription,
                                        }}
                                    ></div>
                                )}
                                {category && (
                                    <BodyContent
                                        content={category.bodyContent}
                                    />
                                )}
                            </Col>
                            <Col xs={12} lg={6}>
                                {Array.isArray(filteredProjects) &&
                                filteredProjects.length ? (
                                    <Projects
                                        items={filteredProjects}
                                        defaultView="list"
                                        mainColor={
                                            category.mainColor || "white"
                                        }
                                    />
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
        </>
    );
};

export default Category;
