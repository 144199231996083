import React, { useContext } from "react";

// Internal components
// import Date from "components/Date";

// External components
import { Badge } from "react-bootstrap";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const StatusSlider = ({
    status,
    start,
    end,
    format = "YYYY",
    color = "white",
}) => {
    const statuses = ["Planlagt", "Under regulering", "I salg", "Innflytting"];
    const { data } = useContext(UmbracoContext);
    return (
        <>
            {status && (
                <div className="status-range">
                    <div className="status-range-grid">
                        {start && start !== "0001-01-01T00:00:00" && (
                            <div className="status-range-grid_start">
                                {/* <Date date={start} format={format} /> */}
                                {start}
                            </div>
                        )}

                        {statuses.map((label, index) => (
                            <Badge
                                pill
                                className={`status-range-grid_label ${
                                    index + 1 === parseInt(status)
                                        ? "status-range-grid_label--current"
                                        : ""
                                }
                                status-range-grid_label--${index + 1}
                                `}
                                style={{
                                    gridColumn: index + 1,
                                    backgroundColor:
                                        index + 1 === parseInt(status)
                                            ? color
                                            : data?.colors?.color4,
                                    // color:
                                    //     index + 1 === parseInt(status)
                                    //         ? ""
                                    //         : data?.colors?.color2,
                                }}
                            >
                                {label}
                            </Badge>
                        ))}
                        <div
                            className={`status-range-grid_point status-range-grid_point--${status}`}
                            style={{ backgroundColor: color }}
                        ></div>
                        {end && end !== "0001-01-01T00:00:00" && (
                            <div className="status-range-grid_end">
                                {/* <Date date={end} format={format} /> */}
                                {end}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default StatusSlider;
