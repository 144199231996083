import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// Routes
import Routes from './Routes';

// External components
import KeyboardEventHandler from 'react-keyboard-event-handler';
import SimpleReactLightbox from 'simple-react-lightbox';
import { Container } from 'react-bootstrap';

// Internal components
import Nav from 'components/Nav';
import Footer from 'components/Footer';
import Map from 'components/Lottie/Map';
import DevMode from 'components/DevMode';
import ScrollToTop from 'components/ScrollToTop';
import Categories from 'components/Categories';
// import SVG from "components/SVG";

// Contexts
import { UmbracoContext } from 'contexts/Umbraco';
import AllHotkeys from 'components/AllHotkeys';

function App() {
  const { data, currentPage } = useContext(UmbracoContext);
  useEffect(() => {
    if (data !== null) {
      // document.body.style.backgroundColor = data.colors.bg;
      document.body.style.color = data.colors.color2;
      document
        .querySelectorAll('h2')
        .forEach((el) => (el.style.color = data.colors.color2));
    }
  }, [data]);
  let currentId = currentPage?.id;
  return (
    <>
      <AllHotkeys />
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        style={{
          visibility: 'hidden',
          pointerEvents: 'none',
          display: 'none',
        }}>
        <defs>
          <filter id='f1' x='-20%' y='-20%' width='160%' height='160%'>
            <feGaussianBlur
              id='b1'
              result='blurOut'
              in='SourceGraphic'
              stdDeviation='1'></feGaussianBlur>
            <feComposite
              id='c1'
              operator='arithmetic'
              k1={0}
              k2={1.5}
              k3={-0.5}
              k4={0}
              in='SourceGraphic'
              in2='blurOut'></feComposite>
          </filter>
        </defs>
      </svg>
      <SimpleReactLightbox>
        {data !== null && (
          <Router>
            <div
              style={{
                boxShadow: '0px 6px 20px #000',
                paddingBottom: '200px',
              }}>
              <ScrollToTop />
              <DevMode />
              <KeyboardEventHandler
                handleKeys={['alt + shift + u']}
                onKeyEvent={(key, e) => {
                  if (window.location.hostname === 'localhost') {
                    window
                      .open(
                        `${
                          currentId
                            ? `https://lundsentrum.no/umbraco#/content/content/edit/${currentId}`
                            : 'https://lundsentrum.no/umbraco'
                        }`,
                        '_blank'
                      )
                      .focus();
                  } else {
                    window
                      .open(
                        `${
                          currentId
                            ? `/umbraco#/content/content/edit/${currentId}`
                            : '/umbraco'
                        }`,
                        '_blank'
                      )
                      .focus();
                  }
                }}
              />
              <KeyboardEventHandler
                handleKeys={['alt + shift + i']}
                onKeyEvent={(key, e) =>
                  window.open(
                    // "http://300434-www.aeston.no/umbraco",
                    'https://imageprocessor.org/imageprocessor/imagefactory/#methods',
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              />
              <Nav />
              <div id='content'>
                <Map />
                {data !== null && <Routes />}
              </div>

              <Container>
                <div style={{ paddingTop: 100 }}>
                  <Categories simple horizontal />
                </div>
              </Container>
            </div>
            <Footer />
          </Router>
        )}
      </SimpleReactLightbox>
    </>
  );
}

export default App;
