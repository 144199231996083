import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Internal components
import Logo from "components/Lottie/Logo";

// External components
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaInstagram } from "react-icons/fa";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const Footer = () => {
    const { data } = useContext(UmbracoContext);
    return (
        <>
            {/* <Container>
                <div style={{ paddingTop: 100 }}>
                    <Categories simple horizontal />
                </div>
            </Container> */}
            <Container className="footer">
                <Row>
                    {/* <Col>
                        {data.footerText && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data.footerText,
                                }}
                                className="mb-4 mb-lg-0"
                            ></div>
                        )}
                        {(data.phone || data.email) && (
                            <Row>
                                {data.phone && (
                                    <Col xs="auto" className="mb-4 mb-lg-0">
                                        <p>Telefonnummer:</p>
                                        <a href={`tel:${data.phone}`}>
                                            <Btn>
                                                <FaMobileAlt className="mr-3" />
                                                {data.phone}
                                            </Btn>
                                        </a>
                                    </Col>
                                )}
                                {data.email && (
                                    <Col xs="auto">
                                        <p>E-post:</p>
                                        <a href={`mailto:${data.email}`}>
                                            <Btn>
                                                <FaEnvelopeOpen className="mr-3" />
                                                {data.email}
                                            </Btn>
                                        </a>
                                    </Col>
                                )}
                            </Row>
                        )}
                    </Col> */}
                    {(data.facebook || data.insta) && (
                        <Col>
                            {data.facebook && (
                                <a
                                    href={data.facebook}
                                    className="p-3 hover-scale"
                                >
                                    <FaFacebook size="3rem" />
                                </a>
                            )}
                            {data.insta && (
                                <a
                                    href={data.insta}
                                    className="p-3 hover-scale"
                                >
                                    <FaInstagram size="3rem" />
                                </a>
                            )}
                        </Col>
                    )}
                    <Col className="justify-content-end d-none d-sm-flex">
                        <Link to="/" style={{ height: 150 }}>
                            <Logo width="15rem" />
                        </Link>
                    </Col>
                </Row>

                <Link to="/" className="d-sm-none w-100 text-center">
                    <Logo width="15rem" />
                </Link>
            </Container>
        </>
    );
};

export default Footer;
