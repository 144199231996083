import React from "react";
import { Link } from "react-router-dom";

// External components
import { FaAngleRight } from "react-icons/fa";
import { RiHomeFill } from "react-icons/ri";

const BreadCrumbs = ({ crumbs }) => {
    return (
        <div className="text-capitalize above-cubes mb-4">
            <Link to="/">
                <RiHomeFill className="mx-2" />
            </Link>
            {crumbs &&
                Array.isArray(crumbs) &&
                crumbs.length &&
                crumbs.map((crumb, index) => (
                    <>
                        <FaAngleRight
                            className="mx-2"
                            key={crumb.title + index + "icon"}
                        />
                        <Link to={crumb.to} key={crumb.title + index + "link"}>
                            {crumb.title}
                        </Link>
                    </>
                ))}
        </div>
    );
};

export default BreadCrumbs;
