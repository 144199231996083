import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./sass/main.scss";

import SimpleReactLightbox from "simple-react-lightbox";
import { UmbracoProvider } from "contexts/Umbraco";

ReactDOM.render(
    <UmbracoProvider>
        <SimpleReactLightbox>
            <App />
        </SimpleReactLightbox>
    </UmbracoProvider>,
    document.getElementById("root")
);
