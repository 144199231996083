import React from "react";
import { Link } from "react-router-dom";

// Internal components
import Btn from "components/Btn";
import Date from "components/Date";
import { FaAngleRight } from "react-icons/fa";
import InternalOrExternalLink from "components/InternalOrExternalLink";

// External components
import { Badge } from "react-bootstrap";
import UmbImage from "./UmbImage";

const SinglePost = ({
    title,
    thumbnail,
    thumbnailSrc,
    thumbnailAltText,
    text,
    bg,
    textColor,
    limitText,
    featured,
    slug,
    btnText,
    mainColor,
    published,
    category,
    id,
    showCategory = false,
    className,
    badge,
}) => {
    return (
        <>
            {category && showCategory && (
                <h1
                    style={{ color: mainColor }}
                    className="text-capitalize post-category"
                    key={id + "title"}
                >
                    {category.replace("_", " ")}
                </h1>
            )}
            <div
                className={`shadow post above-cubes w-100 
              ${className}
                ${featured ? "post--featured" : ""}`}
                style={{
                    backgroundColor: bg,
                }}
                key={id + "box"}
            >
                {thumbnailSrc && (
                    <Link to={slug} style={{ overflow: "hidden" }}>
                        {/* <picture style={{ display: "grid", background: `url(${thumbnailSrc}?width=100&format=jpeg&quality=65) center center / cover no-repeat` }}>
                        </picture> */}
                        <UmbImage
                            imgObj={thumbnail}
                            width={featured ? 1300 : 300}
                            height={featured ? 600 : 300}
                            params="&mode=crop"
                            //     Pad (default)
                            // BoxPad (Added v4.4.0)
                            // Crop
                            // Min (Added v4.4.0)
                            // Max
                            // Stretch
                            // responsive={
                            //     {
                            //         800: {
                            //             width: 800,
                            //             height: 300
                            //         },
                            //         1100: {
                            //             width: 1100,
                            //             height: 300
                            //         },
                            //     }
                            // }
                            className={`post_thumbnail`}
                        />
                        {/* <img
                            src={
                                thumbnailSrc +
                                `?width=${featured ? 1800 : 800
                                }&format=jpeg&quality=65&sharpen(50,50,50)`
                            }
                            alt={thumbnailAltText || ""}
                            className={`post_thumbnail`}
                            key={id + "image"}
                        /> */}
                    </Link>
                )}
                <div
                    className="post_content"
                    style={{
                        color: textColor,
                    }}
                    key={id + "post-content"}
                >
                    <div>
                        <div>
                            {published && (
                                <InternalOrExternalLink
                                    to={slug}
                                    key={id + "post-date-link"}
                                >
                                    <Date date={published} />
                                </InternalOrExternalLink>
                            )}
                            {title && (
                                <h1
                                    className="h2 post_content_title"
                                    key={id + "post-content-title"}
                                >
                                    <InternalOrExternalLink
                                        to={slug}
                                        key={id + "post-title-link"}
                                    >
                                        {title}
                                    </InternalOrExternalLink>
                                </h1>
                            )}
                        </div>
                        {badge && <Badge pill>{badge}</Badge>}
                        {/* {text && (
                            <div
                                className={`post_content_text mb-4`}
                                key={id + "post-content-text"}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        limitText && text.length > 100
                                            ? text.substring(0, 100) + "..."
                                            : text.substring(0, 400) + "...",
                                }}
                            ></div>
                        )} */}
                    </div>
                    {slug && (
                        <InternalOrExternalLink
                            to={slug}
                            key={id + "post-link"}
                            className="d-flex"
                        >
                            <Btn mainColor={mainColor} key={id + "post-btn"}>
                                {btnText || "Les mer"}{" "}
                                <FaAngleRight
                                    size="1.5rem"
                                    className="ml-3"
                                    key={id + "post-btn-icon"}
                                />{" "}
                            </Btn>
                        </InternalOrExternalLink>
                    )}
                </div>
            </div>
        </>
    );
};

export default SinglePost;
