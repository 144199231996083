import React from "react";

// Internal components
import Posts from "components/Posts";
import BreadCrumbs from "components/BreadCrumbs";

// External Components
import { Container } from "react-bootstrap";

const Articles = () => {
    return (
        <Container>
            <BreadCrumbs crumbs={[{ to: "/aktuelt", title: "aktuelt" }]} />
            <Posts />
        </Container>
    );
};

export default Articles;
