
// Styles
import "./UmbImage.css";

const UmbImage = ({ width,
    height,
    q = 90,
    fadeInDuration = ".2s",
    responsive,
    multiplier = 1,
    lazy = true,
    parentClassname,
    imgObj,
    params = "",
    fallbackSrc,
    ...props }) => {
    if (!imgObj && !fallbackSrc) return null;
    let src = imgObj ? imgObj.url : fallbackSrc;
    let alt = imgObj ? imgObj.name : props.altText;
    let png = src?.includes(".png");
    if (!png) {
        multiplier = 1.5;
    }
    let svg = src?.includes(".svg");
    let options = {};
    if (lazy) options.loading = "lazy";

    // const isHighDensity = () => {
    //     return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
    // };
    const getSize = (val, w, multiplier = multiplier) => {
        let v = val * multiplier;
        if (v < 2560) return v;
        else return 2560;
    };
    const getResponsive = (val, multiplier) => {
        if (svg) return src;
        let format = "?format=webp";
        let w = val.width ? `&width=${getSize(val.width, val.width, multiplier)}` : "";
        let h = val.height ? `&height=${getSize(val.height, val.width, multiplier)}` : "";
        let quality = q ? `&quality=${q}` : "";
        let focalPointObj = {};
        let umbFile = imgObj.additionalProperties?.umbracoFile;
        if (typeof umbFile === "string") {
            let str = umbFile?.replace(/.*focalPoint.*left":([0-9]\.[0-9]+).*top":([0-9]\.[0-9]+).*/, "$1,$2")?.split(",");
            focalPointObj = {
                left: str[0],
                top: str[1]
            };
        }
        let focalPoint = imgObj?.focalPoint && focalPointObj ? `&rxy=${focalPointObj?.left || imgObj.focalPoint?.left},${focalPointObj?.top || imgObj.focalPoint?.top}` : "";
        return src + format + h + w + quality + focalPoint + params;
    };
    const getImgUrl = (multiplier = multiplier) => {
        if (svg) return src;
        let format = "?format=webp";
        let w = width ? `&width=${getSize(width, width, multiplier)}` : "";
        let h = height ? `&height=${getSize(height, width, multiplier)}` : "";
        let quality = q ? `&quality=${q}` : "";
        let focalPointObj = {};
        let umbFile = imgObj.additionalProperties?.umbracoFile;
        if (typeof umbFile === "string") {
            let str = umbFile?.replace(/.*focalPoint.*left":([0-9]\.[0-9]+).*top":([0-9]\.[0-9]+).*/, "$1,$2")?.split(",");
            focalPointObj = {
                left: str[0],
                top: str[1]
            };
        }
        let focalPoint = imgObj?.focalPoint && focalPointObj ? `&rxy=${focalPointObj?.left || imgObj.focalPoint?.left},${focalPointObj?.top || imgObj.focalPoint?.top}` : "";
        return src + format + h + w + quality + focalPoint + params;
    };
    let style = { opacity: 0, transition: `${fadeInDuration} ease-out`, width: "100%", height: "100%" };

    let imgSrc = `${imgObj ? getImgUrl(multiplier) : src}`;
    if (imgSrc) {
        return (
            <>
                <picture key={JSON.stringify(imgObj)} className={`umb-image ${props.className || ""}`} style={{ display: "flex", background: svg ? "" : `url("${imgObj?.url}?width=${width / 20}&height=${height / 20}${params}") center center / cover no-repeat` }}>
                    {responsive && Object.entries(responsive)?.map(keyVal => {
                        let mediaQuery = keyVal[0];
                        let val = keyVal[1];
                        let oneX = getResponsive(val, multiplier);
                        let twoX = getResponsive(val, 2);
                        return (<source srcSet={`${oneX} 1x, ${twoX} 2x`} key={oneX} media={`(max-width: ${mediaQuery}px)`} />);
                    }
                    )}
                    <img
                        width={width}
                        height={height}
                        onLoad={(e) => { e.target.style.opacity = 1; }}
                        {...options}
                        style={{ ...props.style, ...style, }}
                        src={imgSrc + `${params}`}
                        alt={alt}
                    />
                </picture>
            </>
        );
    }
};

export default UmbImage;;