import React, { useContext } from "react";
import { Helmet } from "react-helmet";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// External components
import { Container } from "react-bootstrap";

// Internal components
import Intro from "components/Intro";
import Posts from "components/Posts";
import Header from "components/Header";

const Home = () => {
    const { data } = useContext(UmbracoContext);
    return (
        <>
            <Helmet>
                <title>{data.seo.title || data.title}</title>
                <meta
                    property="og:title"
                    content={data.seo.title || data.title}
                />
                <meta
                    name="twitter:title"
                    content={data.seo.title || data.title}
                />
                <meta
                    property="og:image"
                    content={
                        data.seo.featuredImage.url || data.featuredImage.url
                    }
                />
                <meta
                    name="twitter:image"
                    content={
                        data.seo.featuredImage.url || data.featuredImage.url
                    }
                />
                <meta
                    property="og:description"
                    content={data.seo.description}
                />
                <meta
                    name="twitter:description"
                    content={data.seo.description}
                />
                <meta name="description" content={data.seo.description} />
            </Helmet>
            <Container>
                <Header />
            </Container>
            <Intro />

            <Container>
                <Posts limit={15} />
            </Container>
        </>
    );
};

export default Home;
