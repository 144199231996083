import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// External components
// import ReactVivus from "react-vivus";
import SVG from "react-inlinesvg";
// import Lottie from "react-lottie";
// import mapdata from "./mapdata.json";
// import cubesdata from "./cubesdata.json";
// // import Vivus from "vivus";
// import { motion } from "framer-motion";
// import SVG, { Props as SVGProps } from "react-inlinesvg";

// import Cubes from "components/Cubes";
import Cube from "components/Cube";
import { FiExternalLink } from "react-icons/fi";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const Map = ({ className }) => {
    const { data, hideMap, coordinates, projects, currentColor } =
        useContext(UmbracoContext);
    const location = useLocation();

    const getProject = (id, type) => {
        if (id && projects) {
            const filtered = projects?.filter((p) => p.id === id);
            let p = filtered[0];
            if (p) {
                if (type === "url") {
                    return p.url;
                }
                if (type === "tooltip") {
                    return (
                        <>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <p>{p?.title}

                                </p>
                                {p?.url?.startsWith("/") ? null : <small>(ekstern)<FiExternalLink /></small>
                                }
                            </div>
                            <img
                                alt={p?.featuredImage?.altText}
                                src={p?.featuredImage?.url}
                                className="w-100"
                            />
                        </>
                    );
                }
            }
        }
    };
    const CoordCube = ({ c, singleColor, color }) => (
        <Cube
            // x={c.leftPercent + "%"}
            x={`${c.coordinates.leftExact}px`}
            y={`${c.coordinates.topExact}px`}
            // color={`#${c.colors[0] || "fff"}`}
            frontColor={
                !singleColor && c?.colors[0] ? "#" + c?.colors[0] : color
            }
            rightColor={
                !singleColor && c?.colors[1] ? "#" + c?.colors[1] : undefined
            }
            topColor={
                !singleColor && c?.colors[2] ? "#" + c?.colors[2] : undefined
            }
            className="falldown"
            randomPos={false}
            size={parseInt(c?.coordinates?.stickerSize).toFixed() + "px"}
            to={`${getProject(c.nodeId, "url")}`}
            tooltip={getProject(c.nodeId, "tooltip")}
        />
    );
    const [filteredCoordinates, setFilteredCoordinates] = useState(null);
    useEffect(() => {
        if (currentColor) {
            let filtered = coordinates?.filter((c) =>
                c.colors.includes(currentColor.replace("#", ""))
            );
            setFilteredCoordinates(filtered);
        } else {
            setFilteredCoordinates(coordinates);
        }
    }, [currentColor, coordinates]);
    return (
        <>
            {!hideMap && data !== null && data.mapFile && (
                <div
                    className={`map-center ${location.pathname === "/"
                        ? "map-center--home"
                        : "map-center--nothome"
                        } 
                    ${className} 
                    ${location.pathname.includes("prosjekter")
                            ? "map-center--projects"
                            : ""
                        } 
                    ${location.pathname.includes("info")
                            ? "map-center--info"
                            : ""
                        }`}
                >
                    <div className={`map-container`}>
                        <div
                            className="map-intro"
                            style={{ width: 1800, height: 800 }}
                        >
                            <SVG
                                src={data.mapFile}
                                width={1800 + "px"}
                                height={800 + "px"}
                                className="map"
                            />
                            {filteredCoordinates?.map((c) => (
                                <>
                                    <CoordCube
                                        c={c}
                                        singleColor={currentColor}
                                        color={currentColor}
                                    />
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Map;
