import React, { useContext } from "react";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// External components
import { Row, Col, Button } from "react-bootstrap";
import FadeIn from "react-fade-in";
import { FaAngleDown } from "react-icons/fa";

// Internal components
import InternalOrExternalLink from "components/InternalOrExternalLink";

const Header = () => {
    const { data, screenWidth } = useContext(UmbracoContext);
    return (
        <>
            {data !== null && (
                <FadeIn>
                    <div
                        style={{
                            marginBottom: 130,
                        }}
                    >
                        <div
                            className={`text-left above-cubes header`}
                        // style={{
                        //     backgroundColor:
                        //         screenWidth > 700 ? data.colors.bg : "",
                        // }}
                        >
                            <div
                                className="mb-4 d-flex flex-column"
                                style={{ color: data.colors.color1 }}
                            >
                                <p className="small">
                                    <b>{data.featuredContent.subject}</b>
                                </p>
                                <h1 className="underlined">
                                    {data.featuredContent.title}
                                </h1>
                                <div
                                    style={{ color: data.colors.color2 }}
                                    dangerouslySetInnerHTML={{
                                        __html: data.featuredContent.textBody,
                                    }}
                                ></div>
                            </div>
                            <Row>
                                {data !== null &&
                                    Array.isArray(
                                        data.featuredContent.buttons
                                    ) &&
                                    data.featuredContent.buttons.map((btn) => (
                                        <InternalOrExternalLink
                                            to={btn.link}
                                            className=" w-100"
                                        >
                                            <Button
                                                className="px-5 w-100 rounded-pill mb-3"
                                                key={btn.key + "button"}
                                            >
                                                {btn.label}
                                            </Button>
                                        </InternalOrExternalLink>
                                    ))}
                            </Row>
                        </div>
                        <div className="header__arrow-container above-cubes">
                            <FaAngleDown size="2rem" />
                        </div>
                        <div className="py-5" />
                    </div>
                </FadeIn>
            )}
        </>
    );
};

export default Header;
