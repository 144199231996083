import React, { useContext } from "react";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// External components
import { Row, Col, Container } from "react-bootstrap";
import { FaEnvelopeOpen, FaMobileAlt } from "react-icons/fa";

// Internal components
import Btn from "components/Btn";

const Contact = () => {
    const { data } = useContext(UmbracoContext);
    return (
        <>
            {data.footerText && (
                <Container>
                    <Row className="w-100 d-flex justify-content-center above-cubes px-4 pt-5">
                        <Col xs={12} style={{ pointerEvents: "none" }}>
                            <h1>Kontakt oss</h1>
                        </Col>

                        <Col xs={12} md={6} className="mt-md-5 pt-md-5">
                            {data.footerText && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: data.footerText,
                                    }}
                                ></div>
                            )}
                            <hr
                                style={{
                                    borderColor: "rgba(255,255,255,0.1)",
                                }}
                                className="mb-4"
                            />
                            {(data.phone || data.email) && (
                                <Row
                                    className="justify-content-start mx-0"
                                    style={{ gap: "1rem" }}
                                >
                                    {data.phone && (
                                        <Col xs="auto">
                                            <p>Telefonnummer:</p>
                                            <a href={`tel:${data.phone}`}>
                                                <Btn>
                                                    <FaMobileAlt className="mr-3" />
                                                    {data.phone}
                                                </Btn>
                                            </a>
                                        </Col>
                                    )}
                                    {data.email && (
                                        <Col xs="auto">
                                            <p>E-post:</p>
                                            <a href={`mailto:${data.email}`}>
                                                <Btn>
                                                    <FaEnvelopeOpen className="mr-3" />
                                                    {data.email}
                                                </Btn>
                                            </a>
                                        </Col>
                                    )}
                                </Row>
                            )}
                            <hr
                                style={{
                                    borderColor: "rgba(255,255,255,0.1)",
                                }}
                                className="mt-4"
                            />
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default Contact;
