import React, { useContext, useState, useEffect } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// Internal components
import ColorBox from "components/ColorBox";
import { FaAngleLeft } from "react-icons/fa";
import { RiHomeFill } from "react-icons/ri";
import { BiMobileVibration } from "react-icons/bi";
import Logo from "components/Lottie/Logo";
import { useScrollPosition } from "hooks/useScrollPosition";

// External components
import { Button } from "react-bootstrap";
import Hamburger from "hamburger-react";
import FadeIn from "react-fade-in";

const Nav = () => {
    const { data, routeHistory } = useContext(UmbracoContext);
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);
    const history = useHistory();
    useEffect(() => {
        if (menuOpen) {
            document.body.classList.add("noscroll");
        } else {
            document.body.classList.remove("noscroll");
        }
    }, [menuOpen]);
    const resetMenu = () => {
        if (menuOpen) {
            setMenuOpen(false);
        }
    };
    const [hamburgerHeight, setHamburgerHeight] = useState(null);
    useEffect(() => {
        if (document.getElementById("nav-hamburger") !== null) {
            setHamburgerHeight(
                document.getElementById("nav-hamburger").offsetHeight
            );
        }

        // window.addEventListener('resize', updateSize);
    }, []);
    const [currentColor, setCurrentColor] = useState("#fff");
    useEffect(() => {
        if (data !== null) {
            let current = data.indexPages
                .filter((d) => d.slug === location.pathname)
                .map((d) => d.mainColor);
            setCurrentColor(current);
        }
    }, [data, location.pathname]);
    const [sticky, setSticky] = useState(false);

    useScrollPosition(
        ({ prevPos, currPos }) => {
            const isShow = currPos.y < prevPos.y;
            if (isShow !== sticky) setSticky(isShow);
        },
        [sticky]
    );
    return (
        <div
            onClick={() => resetMenu()}
            className={`nav w-100 ${menuOpen ? "nav-open" : ""}`}
        >
            <div className="nav-expanded d-flex">
                <Link to="/" style={{ height: 150 }}>
                    <Logo width="15rem" />
                </Link>
            </div>
            <div>
                {location.pathname !== "/" && (
                    <>
                        {routeHistory.length > 1 ? (
                            <div className="nav-back-wrapper">
                                <Button
                                    onClick={() => history.goBack()}
                                    className="nav_back"
                                    variant="outline-primary"
                                    style={{
                                        borderColor: currentColor,
                                        backgroundColor:
                                            data !== null ? data.colors.color4 : "",
                                        color:
                                            data !== null ? data.colors.color1 : "",
                                    }}
                                >
                                    <FaAngleLeft /> Tilbake
                                </Button>
                            </div>
                        ) : (
                            <div className="nav-back-wrapper">
                                <Link to="/">
                                    <Button
                                        variant="outline-primary"
                                        className={`nav_back ${sticky ? "nav_back--hide" : ""
                                            }`}
                                        style={{
                                            borderColor: currentColor,
                                            backgroundColor:
                                                data !== null
                                                    ? data.colors.color4
                                                    : "",
                                            color:
                                                data !== null
                                                    ? data.colors.color1
                                                    : "",
                                        }}
                                    >
                                        <FaAngleLeft />
                                        <span>Forsiden</span>
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className={`nav-hamburger`}>
                <div
                    className="nav-hamburger_menu"
                    style={{
                        background: menuOpen ? "" : "black",
                        pointerEvents: menuOpen ? "none" : "all",
                    }}
                >
                    <div
                        className="nav-hamburger_menu_toggle"
                        id="nav-hamburger"
                        style={{ color: currentColor || "" }}
                    >
                        <Button
                            variant="transparent"
                            className={`nav-hamburger_menu_toggle_btn ${sticky
                                ? "nav-hamburger_menu_toggle_btn--hide"
                                : ""
                                }`}
                            onClick={(e) => {
                                setMenuOpen(!menuOpen);
                                e.preventDefault();
                            }}
                            style={{
                                color: "inherit",
                            }}
                        >
                            <Hamburger
                                toggled={menuOpen}
                                toggle={setMenuOpen}
                            />
                        </Button>
                    </div>
                    {/* <Logo
                            width="7rem"
                            className="nav-hamburger_menu_logo"
                        /> */}
                </div>
                <div
                    className={`nav-hamburger_submenu ${menuOpen ? "nav-hamburger_submenu--open" : ""
                        }`}
                    style={{
                        // backgroundColor: colors.boxBg + "cc",
                        height: menuOpen ? "100vh" : "",
                    }}
                >
                    <div
                        className="nav-hamburger_submenu_content"
                        style={
                            menuOpen
                                ? {}
                                : {
                                    pointerEvents: "none",
                                    userSelect: "none",
                                }
                        }
                    >
                        <div className="mb-2">
                            <FadeIn>
                                <ColorBox
                                    h1="Hjem"
                                    bgColor="transparent"
                                    iconLeft={
                                        <FaAngleLeft
                                            size="3rem"
                                            className="mr-3"
                                        />
                                    }
                                    iconRight={
                                        <RiHomeFill
                                            size="3rem"
                                            className="ml-3"
                                        />
                                    }
                                    small={true}
                                    textColor={data.colors.color3}
                                    to="/"
                                    key={"home-colorbox"}
                                    arrow={false}
                                />
                            </FadeIn>
                        </div>
                        {menuOpen && (
                            <FadeIn>
                                {data !== null &&
                                    Array.isArray(data.indexPages) &&
                                    data.indexPages.map((d, index) => (
                                        <div
                                            className="mb-2"
                                            key={d.key + "div"}
                                        >
                                            <ColorBox
                                                h1={d.title}
                                                bgColor={d.mainColor}
                                                to={d.slug}
                                                textColor={d.secondaryColor}
                                                current={location.pathname}
                                                key={d.key + "colorbox"}
                                                id={d.id}
                                            />
                                        </div>
                                    ))}
                            </FadeIn>
                        )}
                        <div className="mb-2">
                            <ColorBox
                                h1="Kontakt"
                                bgColor="transparent"
                                textColor={data.colors.color3}
                                small={true}
                                iconLeft={
                                    <BiMobileVibration
                                        size="3rem"
                                        className="mr-3"
                                    />
                                }
                                // textColor={d.secondaryColor}
                                // icon={
                                //     <FaMobileAlt size="3rem" className="mr-3" />
                                // }
                                key={"Kontakt colorbox"}
                                to="/kontakt"
                            // arrow={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{ height: hamburgerHeight }}
                className="d-xl-none"
            ></div>
        </div>
    );
};

export default Nav;

// style={{
//     display: "flex",
//     justifyContent: "flex-start",
//     alignItems: "flex-start",
//     padding: "1vw",
//     width: "100vw",
//     pointerEvents: "none",
// }}
