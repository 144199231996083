import React, { useContext, useState, useEffect } from "react";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// External components
import { format } from "date-fns";

const DateFormat = ({ date, className, dateFormat = "DD/MM/YYYY" }) => {
    const { data } = useContext(UmbracoContext);
    // let d = new window.Date(date.replace(/\./g, "/")).toLocaleDateString();

    const [formattedDate, setFormattedDate] = useState(null);

    useEffect(() => {
        if (date) {
            if (dateFormat === "DD/MM/YYYY") {
                setFormattedDate(date.split(" ")[0].replace(/\./g, "/"));
            } else if (dateFormat === "YYYY") {
                setFormattedDate(date.split(" ")[0].replace(/\./g, "/"));
            } else {
                setFormattedDate(date);
            }
        }
    }, [date, dateFormat]);

    return (
        <>
            {date && (
                // <p
                //     className={`date ${className}`}
                //     style={{ color: data.colors.color3 }}
                // >
                //     {d}
                // </p>
                <span
                    className={`date ${className}`}
                    style={{ color: data.colors.color3 }}
                >
                    {formattedDate && formattedDate.toString()}
                </span>
            )}
        </>
    );
};

export default DateFormat;
