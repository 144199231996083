import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// Internal components
import SinglePost from "components/SinglePost";
import Btn from "components/Btn";
import { FaAngleRight } from "react-icons/fa";
import DiagonalBox from "components/DiagonalBox";

const Posts = ({ current = false, fullWidth, items, limit = 0 }) => {
    const { posts, data, categoryColors, currentPageId } =
        useContext(UmbracoContext);
    const [visiblePosts, setVisiblePosts] = useState([]);
    useEffect(() => {
        if (current && posts && posts && !items) {
            let filtered = posts.filter(
                (post) =>
                    post.category !== null &&
                    post.category !== undefined &&
                    post.category.replace(/\/aktuelt\/([^/]*)\//g, "$1") ===
                    current.toLowerCase()
            );
            if (limit === 0) {
                setVisiblePosts([...filtered]);
            } else {
                setVisiblePosts([...filtered.slice(0, limit)]);
            }
        } else if (!current && posts && !items) {
            if (limit === 0) {
                setVisiblePosts([...posts]);
            } else {
                setVisiblePosts([...posts.slice(0, limit)]);
            }
        } else if (items) {
            if (limit === 0) {
                setVisiblePosts([...items]);
            } else {
                setVisiblePosts([...items.slice(0, limit)]);
            }
        }
    }, [current, posts, items, limit]);

    // useEffect(() => {
    //     console.log(visiblePosts);
    // }, [visiblePosts]);
    return (
        <>
            <div className="px-3 px-md-0">
                {visiblePosts !== null &&
                    Array.isArray(visiblePosts) &&
                    visiblePosts.length > 0 && (
                        <>
                            {currentPageId?.toString() !== "1090" &&
                                data.indexPages
                                    ?.filter((d) => d.id.toString() === "1090")
                                    .map((p) => (
                                        <DiagonalBox
                                            title="Info."
                                            bgColor={p.mainColor || ""}
                                            color={p.secondaryColor || ""}
                                            negativeBottom={true}
                                            key={p.key + "diagonal-box"}
                                        // introText={introText}
                                        // bodyText={bodyText}
                                        />
                                    ))}

                            {/* <h1 className="d-block w-100 above-cubes text-center">
                                Informasjon
                            </h1> */}
                            <div
                                className={
                                    !fullWidth ? "posts-grid" : "posts-nogrid"
                                }
                            >
                                {visiblePosts.map((post, index) => (
                                    <SinglePost
                                        published={post.publishDate}
                                        thumbnail={post.thumbnail}
                                        thumbnailSrc={post.thumbnail.url}
                                        thumbnailAltText={post.thumbnail.title}
                                        title={post.title}
                                        text={post.introText}
                                        limitText={
                                            !current && index === 0
                                                ? false
                                                : true
                                        }
                                        textColor={
                                            data !== null && data.colors.color2
                                        }
                                        bg={data !== null && data.colors.bg}
                                        featured={
                                            !current && index === 0
                                                ? true
                                                : false
                                        }
                                        slug={post.slug}
                                        mainColor={
                                            data !== null &&
                                            data.indexPages
                                                .filter(
                                                    (p) =>
                                                        p.title
                                                            .toLowerCase()
                                                            .replace(
                                                                " ",
                                                                "_"
                                                            ) === post.category
                                                )
                                                .map((item) => item.mainColor)
                                        }
                                        key={post.key + "single-post"}
                                        id={post.key || post.id}
                                    />
                                ))}
                            </div>
                            {limit !== 0 && posts.length > limit && (
                                <div className="d-flex justify-content-center my-5">
                                    <Link to="/aktuelt">
                                        <Btn className="btn-lg">
                                            Se alle artikler
                                            <FaAngleRight className="ml-4" />
                                        </Btn>
                                    </Link>
                                </div>
                            )}
                        </>
                    )}
            </div>
        </>
    );
};

export default Posts;
