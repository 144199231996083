import React, { useContext, useState, useEffect } from "react";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// External components
import { Row, Col, Button, Badge } from "react-bootstrap";
import {
    FaEye,
    FaImage,
    FaList,
    FaPlus,
    FaSearch,
    FaTimes,
    FaTimesCircle,
} from "react-icons/fa";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import FadeIn from "react-fade-in";

// Internal components
import Btn from "components/Btn";
import InternalOrExternalLink from "components/InternalOrExternalLink";

const Projects = ({
    items,
    mainColor,
    id = "",
    // prefix = "prosjekter",
    categorized,
    defaultView,
    isLev,
}) => {
    const { data, getIcon } = useContext(UmbracoContext);
    const [shownItems, setShownItems] = useState([]);
    const defaultVisible = 50;
    const [visible, setVisible] = useState(defaultVisible);
    const [searchFocused, setSearchFocused] = useState(false);
    const [categoryFocused, setCategoryFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [noResults, setNoResults] = useState(false);
    const loadMore = () => {
        setVisible((visible) => visible + defaultVisible);
    };
    const [categories, setCategories] = useState([]);
    const [byCategory, setByCategory] = useState([]);
    const [searchable, setSearchable] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    React.useEffect(() => {
        if (categorized) {
            let allCategories = [];
            items &&
                items.map(
                    (item) =>
                        item.categories !== null &&
                        item.categories.map((c) => allCategories.push(c))
                );

            const seen = new Set();
            const uniqueCategories = allCategories.filter((el) => {
                const duplicate = seen.has(el.id);
                seen.add(el.id);
                return !duplicate;
            });
            setCategories(uniqueCategories);
            // console.log(allCategories);
            setShownItems(items);
            setSearchable(items);
        } else {
            setShownItems(items);
            setSearchable(items);
        }
    }, [items, categorized]);
    // View
    const [view, setView] = useState(defaultView || "thumbnails");
    const [categorySelected, setCategorySelected] = useState(false);
    const doFilter = (value) => {
        if (value) {
            let categoryStrings = categories.map((c) => c.title);
            let filtered = items.filter(
                (p) =>
                    p.categories !== null &&
                    p.categories.some((cat) =>
                        categoryStrings.includes(cat.title)
                    )
            );
            setShownItems(filtered);
            setByCategory(filtered);
            setCategorySelected(true);
            setSearchable(filtered);
        } else {
            setShownItems(items);
            setSearchable(items);
            setCategorySelected(false);
        }
    };
    const doSearch = (query) => {
        setNoResults(false);
        if (query.length > 1) {
            let filtered = shownItems.filter((p) =>
                p.tags
                    ? p.tags
                        .concat(p.title)
                        .toString()
                        .toLowerCase()
                        .includes(query.toLowerCase())
                    : p.title.toLowerCase().includes(query.toLowerCase())
            );
            if (filtered.length > 0) {
                setShownItems(filtered);
                setVisible(filtered.length);
            } else {
                setShownItems([]);
                setVisible(filtered.length);
                setNoResults(true);
            }
        } else if (query.length === 0) {
            if (categorySelected) {
                setShownItems(byCategory);
            } else {
                setShownItems(searchable);
            }
            setVisible(defaultVisible);
        }
        // console.log(shownItems);
    };
    const [cats, setCats] = useState([]);
    useEffect(() => {
        if (shownItems) {
            function createCategories(data) {
                if (data !== null) {
                    let categories = {};
                    data.forEach((entity) => {
                        if (entity.categories === null) {
                            if (!categories["Ukategorisert"]) {
                                categories["Ukategorisert"] = [];
                            }
                            categories["Ukategorisert"].push(entity);
                        } else {
                            if (selectedCategory) {
                                entity.categories.forEach((cat) => {
                                    if (!categories[selectedCategory]) {
                                        categories[selectedCategory] = [];
                                    }
                                    if (cat.title === selectedCategory) {
                                        categories[selectedCategory].push({
                                            ...entity,
                                            categoryUrl: cat.url,
                                        });
                                    }
                                });
                            } else {
                                entity.categories.forEach((cat) => {
                                    if (!categories[cat.title]) {
                                        categories[cat.title] = [];
                                    }
                                    categories[cat.title].push({
                                        ...entity,
                                        categoryUrl: cat.url,
                                    });
                                });
                            }
                        }
                    });
                    // console.log(categories);
                    return categories;
                }
            }
            setCats(createCategories(shownItems));
        }
    }, [shownItems, selectedCategory]);
    useEffect(() => {
        if (id && id.toString() === "1092") {
            setView("list");
        }
    }, [id]);
    return (
        <div
            style={{
                backgroundColor:
                    id.toString() === "1092" ? mainColor : data.colors.bg,
            }}
            className="p-3 p-md-4 shadow above-cubes"
        >
            <Row className="mb-2 mx-0">
                <Col className="mb-4 p-0">
                    <Button
                        className="mr-2"
                        onClick={() => setView("list")}
                        style={{
                            backgroundColor: data.colors.color4,
                            borderColor: "transparent",
                            color:
                                view === "list"
                                    ? mainColor
                                    : data.colors.color3,
                        }}
                    >
                        <FaList />
                    </Button>
                    <Button
                        onClick={() => setView("thumbnails")}
                        style={{
                            backgroundColor: data.colors.color4,
                            borderColor: "transparent",
                            color:
                                view === "thumbnails"
                                    ? mainColor
                                    : data.colors.color3,
                        }}
                    >
                        <FaImage />
                    </Button>
                </Col>
                {categorized && categories.length ? (
                    <Col className="p-0">
                        <select
                            id="projects-category"
                            name="categories"
                            className="field"
                            style={{
                                backgroundColor: data.colors.color4,
                                borderColor: categoryFocused
                                    ? mainColor
                                    : "transparent",
                                outline: categoryFocused
                                    ? mainColor
                                    : "transparent",
                                color: "inherit",
                            }}
                            onChange={(e) => {
                                doFilter(e.target.value);
                                setSelectedCategory(e.target.value);
                            }}
                            onFocus={() => setCategoryFocused(true)}
                            onBlur={() => setCategoryFocused(false)}
                        >
                            <option selected value="">
                                Velg kategori
                            </option>
                            {categories.map((c) => (
                                <option value={c.title} key={c.title}>
                                    {c.title}
                                </option>
                            ))}
                        </select>
                    </Col>
                ) : null}
                <Col className="p-0 d-flex justify-content-end">
                    <label
                        style={{
                            backgroundColor: isLev ? data.colors.bg : "rgba(0,0,0,0.4)",
                            borderColor: searchFocused
                                ? mainColor
                                : "transparent",
                            minWidth: 300,
                            width: "100%",
                        }}
                        onFocus={() => setSearchFocused(true)}
                        onBlur={() => setSearchFocused(false)}
                        className="field"
                    >
                        <input
                            onChange={(e) => {
                                doSearch(e.target.value);
                                setSearchQuery(e.target.value);
                            }}
                            id="projects-search"
                            type="search"
                            placeholder="Søk etter prosjekt"
                            style={{
                                border: "none",
                                color: data.colors.color1,
                                background: "transparent",
                            }}
                        />
                        <FaSearch
                            color={mainColor}
                            size="1.5rem"
                            className="ml-2"
                        />
                    </label>
                </Col>
            </Row>
            {(selectedCategory || searchQuery) && (
                <FadeIn>
                    <Row
                        className="mb-5 flex-column justify-content-center "
                        style={{ gap: "1rem" }}
                    >
                        <FadeIn>
                            {selectedCategory && !noResults && (
                                <div className="d-flex justify-content-center text-center align-items-center">
                                    {/* <FaTimesCircle size="1.5rem" className="mr-3" /> */}
                                    <p
                                        className="mb-2"
                                        style={{
                                            color:
                                                id && id.toString() === "1092"
                                                    ? "black"
                                                    : "",
                                        }}
                                    >
                                        Valgt kategori:{" "}
                                        <strong
                                            style={{
                                                color: mainColor,
                                                background:
                                                    id &&
                                                        id.toString() === "1092"
                                                        ? "rgb(46, 46, 46)"
                                                        : "",
                                                padding: "0.2rem 0.5rem",
                                            }}
                                            className="rounded"
                                        >
                                            {selectedCategory}
                                        </strong>
                                    </p>
                                </div>
                            )}
                            {searchQuery && !noResults && (
                                <div className="d-flex justify-content-center text-center align-items-center">
                                    {/* <FaTimesCircle size="1.5rem" className="mr-3" /> */}
                                    <p
                                        className="mb-0"
                                        style={{
                                            color:
                                                id && id.toString() === "1092"
                                                    ? "black"
                                                    : "",
                                        }}
                                    >
                                        Søketreff på:{" "}
                                        <strong
                                            style={{
                                                color: mainColor,
                                                background:
                                                    id &&
                                                        id.toString() === "1092"
                                                        ? "rgb(46, 46, 46)"
                                                        : "",
                                                padding: "0.2rem 0.5rem",
                                            }}
                                            className="rounded"
                                        >
                                            {searchQuery}
                                        </strong>
                                    </p>
                                </div>
                            )}
                            {noResults && searchQuery && (
                                <>
                                    <div
                                        className="d-flex justify-content-center text-center align-items-center mb-4"
                                        style={{
                                            color:
                                                id && id.toString() === "1092"
                                                    ? "black"
                                                    : "",
                                        }}
                                    >
                                        <FaTimesCircle
                                            size="1.5rem"
                                            className="mr-3"
                                            color={mainColor}
                                        />
                                        <p className="mb-0">
                                            Ingen treff på{" "}
                                            <strong
                                                style={{
                                                    color: mainColor,
                                                    background:
                                                        id &&
                                                            id.toString() === "1092"
                                                            ? "rgb(46, 46, 46)"
                                                            : "",
                                                    padding: "0.2rem 0.5rem",
                                                }}
                                                className="rounded"
                                            >
                                                {searchQuery}
                                            </strong>{" "}
                                            {selectedCategory && (
                                                <>
                                                    innenfor kategorien{" "}
                                                    <strong
                                                        style={{
                                                            color: mainColor,
                                                            background:
                                                                id &&
                                                                    id.toString() ===
                                                                    "1092"
                                                                    ? "rgb(46, 46, 46)"
                                                                    : "",
                                                            padding:
                                                                "0.2rem 0.5rem",
                                                        }}
                                                        className="rounded"
                                                    >
                                                        {selectedCategory}
                                                    </strong>
                                                </>
                                            )}
                                        </p>
                                    </div>
                                </>
                            )}
                            <Btn
                                className="mx-auto mt-3"
                                onClick={() => {
                                    document.getElementById(
                                        "projects-search"
                                    ).value = "";
                                    doSearch("");
                                    setSearchQuery("");

                                    document.getElementById(
                                        "projects-category"
                                    ).value = "";
                                    doFilter("");
                                    setSelectedCategory("");
                                }}
                            >
                                <FaTimes size="1.5rem" className="mr-3" />
                                Fjern{" "}
                                {selectedCategory && !searchQuery && "kategori"}
                                {!selectedCategory && searchQuery && "søk"}
                                {selectedCategory &&
                                    searchQuery &&
                                    "søk og kategori"}
                            </Btn>
                        </FadeIn>
                    </Row>
                </FadeIn>
            )}
            <Row
                className={`mx-0 px-0 ${visible < shownItems.length &&
                    !searchQuery &&
                    !selectedCategory
                    ? "mask-bottom"
                    : ""
                    } d-block`}
            >
                {cats && id && id.toString() === "1092" ? (
                    Object.keys(cats).map((cat, index) => {
                        return (
                            <FadeIn>
                                <>
                                    {cats[cat][0]?.categoryUrl ? (
                                        <InternalOrExternalLink
                                            to={cats[cat][0].categoryUrl}
                                            className="mb-3 d-block"
                                            key={index + cat + "link"}
                                        >
                                            <Btn
                                                className="w-100"
                                                key={index + cat + "btn"}
                                            >
                                                <p
                                                    className="d-flex align-items-center h4"
                                                    style={{ gap: "1rem" }}
                                                    key={
                                                        index +
                                                        cat +
                                                        "paragraph"
                                                    }
                                                >
                                                    {getIcon(
                                                        cat,
                                                        mainColor,
                                                        "2rem"
                                                    )}
                                                    {cat}
                                                </p>
                                            </Btn>
                                        </InternalOrExternalLink>
                                    ) : (
                                        <>
                                            {cats.length > 1 && (
                                                <p
                                                    className="bg-dark h4  p-3 rounded d-flex align-items-center"
                                                    style={{ gap: "1rem" }}
                                                    key={
                                                        index +
                                                        cat +
                                                        "paragraph-alternative"
                                                    }
                                                >
                                                    {getIcon(
                                                        cat,
                                                        mainColor,
                                                        "2rem"
                                                    )}
                                                    {cat}
                                                </p>
                                            )}
                                        </>
                                    )}
                                </>
                                <Row className="px-md-2">
                                    {/* <pre>{JSON.stringify(shownItems, null, 1)}</pre> */}
                                    {cats[cat].map((p) => (
                                        <Col md={6}>
                                            {/* <pre class="above-cubes">
                                                {JSON.stringify(
                                                    venues &&
                                                        venues[0].url.split(
                                                            "/"
                                                        )[1],
                                                    null,
                                                    1
                                                )}
                                            </pre> */}
                                            {p.url && (
                                                <InternalOrExternalLink
                                                    key={
                                                        id +
                                                        "-" +
                                                        p.title +
                                                        "-link"
                                                    }
                                                    to={
                                                        p.url
                                                        // isLev
                                                        //     ? p.url.replace(
                                                        //           p.url.split(
                                                        //               "/"
                                                        //           )[1],
                                                        //           "steder"
                                                        //       )
                                                        //     : p.url
                                                    }
                                                    // routePrefix="/prosjekter"
                                                    className={`projects__item ${view === "thumbnails"
                                                        ? "projects__item--thumbnails"
                                                        : ""
                                                        }
                                                    ${
                                                        // id.toString() === "1087"
                                                        //     ? "projects__item--thumbnails-on-top"
                                                        //     : ""
                                                        ""
                                                        }
                                                    ${view === "thumbnails"
                                                            ? "projects__item--thumbnails-on-top"
                                                            : ""
                                                        }
                                                    `}
                                                // If bo page (1087)
                                                >
                                                    {view === "thumbnails" && (
                                                        <FadeIn>
                                                            <div class="bg-dark rounded">
                                                                <img
                                                                    src={
                                                                        p.featuredImage
                                                                            ? p
                                                                                .featuredImage
                                                                                .url +
                                                                            "&width=900&format=jpeg&quality=65&sharpen(50,50,50)"
                                                                            : "https://via.placeholder.com/900x500"
                                                                    }
                                                                    key={
                                                                        id +
                                                                        "-" +
                                                                        "image" +
                                                                        (p
                                                                            .featuredImage
                                                                            ?.altText ||
                                                                            p
                                                                                .featuredImage
                                                                                ?.title ||
                                                                            "")
                                                                    }
                                                                    style={{
                                                                        opacity:
                                                                            !p.featuredImage &&
                                                                            0,
                                                                    }}
                                                                    className="rounded projects__item__thumb"
                                                                    alt={
                                                                        p
                                                                            .featuredImage
                                                                            ?.altText ||
                                                                        p
                                                                            .featuredImage
                                                                            ?.title ||
                                                                        ""
                                                                    }
                                                                />
                                                            </div>
                                                        </FadeIn>
                                                    )}
                                                    <Btn
                                                        mainColor={mainColor}
                                                        to={p.url}
                                                        key={
                                                            id +
                                                            "-" +
                                                            p.title +
                                                            "-btn"
                                                        }
                                                        style={
                                                            id.toString() ===
                                                                "1092"
                                                                ? {
                                                                    backgroundColor:
                                                                        "transparent",
                                                                    color: "black",
                                                                }
                                                                : {}
                                                        }
                                                        className={`projects__item__btn`}
                                                    >
                                                        {id.toString() ===
                                                            "1092" ? (
                                                            <li>
                                                                <span>
                                                                    {p.title}
                                                                </span>
                                                            </li>
                                                        ) : (
                                                            <>
                                                                <span>
                                                                    {p.title}
                                                                </span>
                                                                <FaEye className="ml-3" />
                                                            </>
                                                        )}
                                                    </Btn>
                                                    {searchQuery && (
                                                        <div
                                                            className="d-flex"
                                                            style={{
                                                                flexWrap:
                                                                    "wrap",
                                                                gap: "0.3rem",
                                                            }}
                                                        >
                                                            {p.tags?.map(
                                                                (tag) => (
                                                                    <Badge
                                                                        className="d-inline-block"
                                                                        key={
                                                                            tag
                                                                        }
                                                                        style={{
                                                                            border: "1px solid",
                                                                        }}
                                                                    >
                                                                        {tag}
                                                                    </Badge>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                </InternalOrExternalLink>
                                            )}
                                        </Col>
                                    ))}
                                </Row>
                            </FadeIn>
                        );
                    })
                ) : (
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{
                            350: 1,
                            750: 2,
                        }}
                    >
                        <Masonry>
                            {shownItems &&
                                Array.isArray(shownItems) &&
                                shownItems.length > 0 &&
                                shownItems.slice(0, visible).map(
                                    (p) =>
                                        p.url !== undefined &&
                                        p.url !== null &&
                                        p.url !== "0" && (
                                            <FadeIn>
                                                <InternalOrExternalLink
                                                    key={
                                                        id +
                                                        "-" +
                                                        p.title +
                                                        "-link"
                                                    }
                                                    // to={p.url}
                                                    to={`${p.url}`}
                                                    // routePrefix="/prosjekter"
                                                    className={`projects__item ${view === "thumbnails"
                                                        ? "projects__item--thumbnails"
                                                        : ""
                                                        }
                                            ${id.toString() === "1087"
                                                            ? "projects__item--thumbnails-on-top"
                                                            : ""
                                                        }
                                            ${view === "thumbnails"
                                                            ? "projects__item--thumbnails-on-top"
                                                            : ""
                                                        }
                                            `}
                                                // If bo page (1087)
                                                >
                                                    {view === "thumbnails" &&
                                                        p.featuredImage !==
                                                        null && (
                                                            <FadeIn>
                                                                <img
                                                                    src={
                                                                        p
                                                                            .featuredImage
                                                                            .url +
                                                                        "?width=900&format=jpeg&quality=65&sharpen(50,50,50)"
                                                                    }
                                                                    key={
                                                                        id +
                                                                        "-" +
                                                                        "image" +
                                                                        (p
                                                                            .featuredImage
                                                                            .altText ||
                                                                            p
                                                                                .featuredImage
                                                                                .title ||
                                                                            "")
                                                                    }
                                                                    className="rounded projects__item__thumb"
                                                                    alt={
                                                                        p
                                                                            .featuredImage
                                                                            .altText ||
                                                                        p
                                                                            .featuredImage
                                                                            .title ||
                                                                        ""
                                                                    }
                                                                />
                                                            </FadeIn>
                                                        )}
                                                    <Btn
                                                        mainColor={mainColor}
                                                        to={p.url}
                                                        key={
                                                            id +
                                                            "-" +
                                                            p.title +
                                                            "-btn"
                                                        }
                                                        className="projects__item__btn"
                                                    >
                                                        <span>{p.title}</span>
                                                        <FaEye className="ml-3" />
                                                    </Btn>
                                                </InternalOrExternalLink>
                                            </FadeIn>
                                        )
                                )}
                        </Masonry>
                    </ResponsiveMasonry>
                )}
            </Row>
            <Row>
                <Col xs={12} className="text-center">
                    {visible < shownItems.length &&
                        !searchQuery &&
                        !selectedCategory && (
                            <Button
                                onClick={loadMore}
                                className="rounded-circle d-flex align-items-center justify-content-center mx-auto p-0"
                                style={{
                                    width: "3rem",
                                    height: "3rem",
                                    marginTop: "-2rem",
                                    backgroundColor: mainColor,
                                    borderColor: mainColor,
                                    transform: "scale(1.5)",
                                }}
                            >
                                <FaPlus />
                            </Button>
                        )}
                </Col>
            </Row>
        </div>
    );
};

export default Projects;
