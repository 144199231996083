import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

// Internal components
import ColorBox from "components/ColorBox";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";
const Categories = ({ simple = false, horizontal, small }) => {
    const { data } = useContext(UmbracoContext);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const updateSize = () => {
        setScreenWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", updateSize);
    }, []);
    const location = useLocation();
    return (
        <>
            {!simple && (
                <div className="box-grid">
                    {data !== null &&
                        Array.isArray(data.indexPages) &&
                        data.indexPages.map((d, index) =>
                            index === 0 ? (
                                <ColorBox
                                    h1={d.title}
                                    p={d.introText}
                                    bgColor={d.mainColor}
                                    textColor={d.secondaryColor}
                                    current={location.pathname}
                                    to={d.slug}
                                    key={d.key + "-colorbox"}
                                    id={d.id}
                                    rotated={screenWidth > 1200 ? true : false}
                                />
                            ) : (
                                <ColorBox
                                    h1={d.title}
                                    p={d.introText}
                                    bgColor={d.mainColor}
                                    to={d.slug}
                                    key={d.key + "-colorbox"}
                                    textColor={d.secondaryColor}
                                    current={location.pathname}
                                    id={d.id}
                                />
                            )
                        )}
                </div>
            )}
            {simple && (
                <div
                    className={`d-flex ${!horizontal
                        ? "flex-column"
                        : "categories-simple-horizontal"
                        } p-5 categories-simple`}
                >
                    {data !== null &&
                        Array.isArray(data.indexPages) &&
                        [...data.indexPages
                            // .filter((d) => d.slug !== location.pathname)
                        ]
                            .sort((a, b) => a.slug.length - b.slug.length)
                            .map((d, index) => (
                                <Link
                                    to={d.slug}
                                    key={d.key + "-link"}
                                    className={`font-weight-bold d-inline-block ${!small ? "h1" : "categories-link--small"
                                        }`}
                                    style={{ color: d.mainColor }}
                                >
                                    {d.title}
                                </Link>
                            ))}
                </div>
            )}
        </>
    );
};

export default Categories;
