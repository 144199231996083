import React from "react";

// External components
import { FaAngleUp } from "react-icons/fa";

// Internal components
import Btn from "components/Btn";

const ScrollTop = ({ mainColor }) => {
    return (
        <Btn
            mainColor={mainColor}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
            Til toppen <FaAngleUp className="mx-2" />
        </Btn>
    );
};

export default ScrollTop;
