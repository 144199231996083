import React, { useEffect, useState, useContext } from "react";

// External components
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import FadeIn from "react-fade-in";

// Internal components
import Btn from "components/Btn";
import InternalOrExternalLink from "components/InternalOrExternalLink";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const Categories = () => {
    const { getIcon, allCategories } = useContext(UmbracoContext);
    const [page, setPage] = useState({});
    useEffect(() => {
        axios({
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                mode: "no-cors",
                cache: "no-cache",
            },
            url: "/umbraco/api/headless/GetPageTree",
        }).then(function (response) {
            let categoriesPage = response.data.filter((p) => p.id === 1463);
            setPage(categoriesPage[0]);
        });
    }, []);
    return (
        <>
            <Container>
                <Row className="above-cubes pt-5 mt-5 px-4">
                    <Col>
                        <h1>{page.title}</h1>
                        {allCategories && Array.isArray(allCategories) && (
                            <FadeIn>
                                <Row
                                    style={{ gap: "1rem", flexWrap: "wrap" }}
                                    className="px-md-4"
                                >
                                    {allCategories.map((c) => (
                                        <InternalOrExternalLink
                                            to={c.url}
                                            className="d-flex flex-column align-items-center"
                                        >
                                            <Btn
                                                className="btn-lg mb-2"
                                                key={"btn-" + c.key}
                                            >
                                                {getIcon(c.title, "white")}
                                            </Btn>
                                            {c.title}
                                        </InternalOrExternalLink>
                                    ))}
                                </Row>
                            </FadeIn>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Categories;
