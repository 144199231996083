import React from "react";

// Transition
import { motion, AnimatePresence } from "framer-motion";

const Animate = ({
    trigger,
    children,
    style,
    delay,
    span = false,
    duration, initial, animate, exit
}) => {
    return (
        <AnimatePresence style={style}>
            {trigger && (
                <motion.div
                    className={`${span ? "d-inline-block" : ""}`}
                    // style={{ overflow: "hidden" }}
                    initial={initial || {
                        opacity: 0,
                        translateY: 15,
                        // height: 0,
                    }}
                    animate={animate || {
                        opacity: 1,
                        translateY: 0,
                        // height: "auto",
                    }}
                    exit={exit || {
                        opacity: 0,
                        translateY: 15,
                        // height: 0,
                    }}
                    transition={{
                        duration: duration || 0.4,
                    }}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Animate;
