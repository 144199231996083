import React from "react";
import SVG from "react-inlinesvg";

const CustomGradientSvg = ({ src, size, key, color, width, height }) => {
    return (
        <div className="icon">
            <SVG
                src={src}
                key={key}
                width={width || size}
                height={height || size}
                className="icon"
                uniquifyIDs={true}
                onLoad={() =>
                    document
                        .querySelectorAll(".icon linearGradient > stop")
                        .forEach((el) => el.setAttribute("stop-color", color))
                }
            />
        </div>
    );
};

export default CustomGradientSvg;
