import React from "react";

import { motion } from "framer-motion";

const DiagonalBox = ({
    title,
    text,
    bgColor,
    color,
    bodyText,
    introText,
    negativeBottom,
    children, className
}) => {
    return (
        <>
            <motion.div
                initial={{
                    opacity: 0,
                    translateY: 15,
                    height: 0,
                }}
                animate={{
                    opacity: 1,
                    translateY: 0,
                    height: "auto",
                }}
                exit={{
                    opacity: 0,
                    translateY: 15,
                    height: 0,
                }}
                transition={{
                    duration: 0.4,
                }}
                className={className}
            >
                <div
                    className={`diagonal-wrapper ${negativeBottom ? "diagonal-wrapper--bottom" : ""
                        } `}
                >
                    <div
                        style={{ background: bgColor, color: color }}
                        className={`diagonal-box above-cubes ${negativeBottom ? "diagonal-box--bottom" : ""
                            } `}
                    >
                        <h1
                            dangerouslySetInnerHTML={{
                                __html: title,
                            }}
                            className="diagonal-box_title"
                        ></h1>
                    </div>
                </div>
                <div className="w-100 d-flex flex-column align-items-center px-5">
                    {introText && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: introText,
                            }}
                            style={{ maxWidth: 700 }}
                            className="h2 above-cubes diagonal-box_intro"
                        ></div>
                    )}
                    {bodyText && (
                        <div
                            className="above-cubes diagonal-box_text"
                            style={{ maxWidth: 700 }}
                            dangerouslySetInnerHTML={{
                                __html: bodyText,
                            }}
                        ></div>
                    )}
                    {children}
                </div>
            </motion.div>
        </>
    );
};

export default DiagonalBox;
