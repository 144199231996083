import React from "react";

// Assets
import enebolig from "assets/icons/boligtype_01_enebolig.svg";
import eneboligRekke from "assets/icons/boligtype_02_enebolig_i_rekke.svg";
import rekkehus from "assets/icons/boligtype_03_rekkehus.svg";
import tomannsbolig from "assets/icons/boligtype_04_tomannsbolig.svg";
import firemannsbolig from "assets/icons/boligtype_05_firemannsbolig.svg";
import leilighet from "assets/icons/boligtype_06_leilighet.svg";

// Internal components
import CustomGradientSvg from "components/CustomGradientSvg";

// External components
import { Row, Col } from "react-bootstrap";

const BuildingTypes = ({ types, color }) => {
    return (
        <>
            <Row className="align-items-end" style={{ gap: "1rem" }}>
                {types.map((b) => (
                    <Col xs={6} sm={3} md={2} className="text-center mb-4">
                        <div className="w-100 mb-2">
                            {b.toLowerCase() === "enebolig" && (
                                <CustomGradientSvg
                                    src={enebolig}
                                    width="100%"
                                    color={color}
                                />
                            )}
                            {b.toLowerCase() === "enebolig i rekke" && (
                                <CustomGradientSvg
                                    src={eneboligRekke}
                                    width="100%"
                                    color={color}
                                />
                            )}
                            {b.toLowerCase() === "rekkehus" && (
                                <CustomGradientSvg
                                    src={rekkehus}
                                    width="100%"
                                    color={color}
                                />
                            )}
                            {b.toLowerCase() === "tomannsbolig" && (
                                <CustomGradientSvg
                                    src={tomannsbolig}
                                    width="100%"
                                    color={color}
                                />
                            )}
                            {b.toLowerCase() === "firemannsbolig" && (
                                <CustomGradientSvg
                                    src={firemannsbolig}
                                    width="100%"
                                    color={color}
                                />
                            )}
                            {b.toLowerCase() === "leilighet" && (
                                <CustomGradientSvg
                                    src={leilighet}
                                    width="100%"
                                    color={color}
                                />
                            )}
                        </div>
                        <div style={{ minHeight: 150 }}>{b}</div>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default BuildingTypes;
