import React, { useContext } from "react";

import Carousel from "nuka-carousel";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import Btn from "components/Btn";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const Slider = ({ children, mainColor, arrows }) => {
    const { data } = useContext(UmbracoContext);
    const [ref, setRef] = React.useState();

    React.useEffect(() => {
        ref !== undefined && ref.handleFocus();
    }, [ref]);
    return (
        <>
            <Carousel
                ref={(carouselRef) => setRef(carouselRef)}
                slidesToShow={1}
                slidesToScroll="auto"
                enableKeyboardControls={true}
                // cellAlign="center"
                transitionMode="scroll3d"
                animation="zoom"
                dragging={true}
                swiping={true}
                defaultControlsConfig={{
                    pagingDotsContainerClassName: "dots",
                    pagingDotsStyle: {
                        fill: mainColor,
                        margin: "1rem",
                        transform: "scale(2)",
                    },
                }}
                // wrapAround={true}
                renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                    arrows &&
                    currentSlide !== 0 && (
                        <button
                            onClick={previousSlide}
                            style={{ background: "transparent" }}
                        >
                            <Btn
                                className="rounded-circle d-flex align-items-center justify-content-center mx-auto"
                                style={{
                                    width: "4rem",
                                    height: "4rem",
                                    fontSize: "2.5rem",
                                    backgroundColor: mainColor,
                                    borderColor: mainColor,
                                    color: data.colors.bg,
                                }}
                            >
                                <FaAngleLeft />
                            </Btn>
                        </button>
                    )
                }
                renderCenterRightControls={({ nextSlide, currentSlide }) =>
                    arrows &&
                    currentSlide !== children.length - 1 && (
                        <button
                            onClick={nextSlide}
                            style={{ background: "transparent" }}
                        >
                            <Btn
                                className="rounded-circle d-flex align-items-center justify-content-center mx-auto"
                                style={{
                                    width: "4rem",
                                    height: "4rem",
                                    fontSize: "2.5rem",
                                    backgroundColor: mainColor,
                                    borderColor: mainColor,
                                    color: data.colors.bg,
                                }}
                            >
                                <FaAngleRight />
                            </Btn>
                        </button>
                    )
                }
            >
                {children}
            </Carousel>
        </>
    );
};

export default Slider;
