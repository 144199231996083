import React, { useContext } from "react";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// Internal components
import Categories from "components/Categories";

// External components
import { Row, Col } from "react-bootstrap";

const Intro = () => {
    const { data } = useContext(UmbracoContext);
    return (
        <>
            <Row>
                <Col className="text-center">
                    {data !== null && (
                        <div
                            className="h1"
                            style={{
                                color:
                                    data !== null && data.colors !== null
                                        ? data.colors.color1
                                        : "",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: data.indexSectionText,
                            }}
                        ></div>
                    )}
                </Col>
            </Row>
            <Row className="w-100 align-items-start justify-content-center intro">
                {/* <Col
                    xs={12}
                    md={6}
                    lg={12}
                    xl={4}
                    className="d-flex justify-content-center justify-content-xl-end"
                >
                    <div className="mb-5 p-4 p-md-5 p-md-0 h2">
                        {data !== null && (
                            <div
                                className="paragraph"
                                style={{
                                    color:
                                        data !== null && data.colors !== null
                                            ? data.colors.color1
                                            : "",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: data.indexSectionText,
                                }}
                            ></div>
                        )}
                    </div>
                </Col> */}
                <Col
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="d-flex justify-content-center"
                >
                    <Categories />
                </Col>
            </Row>
        </>
    );
};

export default Intro;
