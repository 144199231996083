import React, { createContext, useState, useEffect } from "react";

import axios from "axios";

import { FaStoreAlt, FaWarehouse } from "react-icons/fa";
import trening from "assets/icons/trening.svg";
import tur from "assets/icons/tur.svg";
// import SVG from "react-inlinesvg";
import CustomGradientSvg from "components/CustomGradientSvg";

export const UmbracoContext = createContext();
export const UmbracoProvider = ({ children }) => {
    const [data, setData] = useState(null);
    const [posts, setPosts] = useState(null);
    const [coordinates, setCoordinates] = useState(null);
    const [projects, setProjects] = useState(null);
    const [venues, setVenues] = useState(null);
    const [projectsAndVenues, setProjectsAndVenues] = useState(null);
    const [routeHistory, setRouteHistory] = useState([]);
    const [categoryColors, setCategoryColors] = useState("");
    const [hideMap, setHideMap] = useState(false);
    const [currentPageId, setCurrentPageId] = useState(null);
    const [currentColor, setCurrentColor] = useState(null);

    /* ********************************** */
    /*              GET PAGES             */
    /* ********************************** */

    useEffect(() => {
        axios({
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                mode: "no-cors",
                cache: "no-cache",
            },
            url: "/umbraco/api/pages/GetPages",
        }).then(function (response) {
            setData({
                ...response.data,
                colors: {
                    bg: "#363636",
                    color1: "#FFFFFF",
                    color2: "#D2D0C6",
                    color3: "#A5A3A3",
                    color4: "#2E2E2E",
                },
                baseName: "Lund Sentrum",
            });
            let array = [];
            response.data.indexPages.forEach((page) =>
                array.push(page.mainColor)
            );
            // console.log(response.data);
            setCategoryColors([...array]);
            // console.log(response.data);
        });
    }, []);

    /* ********************************** */
    /*            GET ALL POSTS           */
    /* ********************************** */

    useEffect(() => {
        axios({
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                mode: "no-cors",
                cache: "no-cache",
            },
            url: "/umbraco/api/posts/getAll",
        }).then(function (response) {
            if (Array.isArray(response.data)) {
                let sorted = response?.data?.sort((a, b) => a.publishDate < b.publishDate ? +1 : -1);
                setPosts(sorted);
            }
            // console.log(response.data);
        });
    }, []);

    /* ********************************** */
    /*           MAP COORDINATES          */
    /* ********************************** */

    useEffect(() => {
        axios({
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                mode: "no-cors",
                cache: "no-cache",
            },
            url: "/umbraco/api/mapCoordinates/GetCoordinates",
        }).then(function (response) {
            // console.log(response.data);
            setCoordinates(response.data);
        });
    }, []);

    /* ********************************** */
    /*         GET ALL CATEGORIES         */
    /* ********************************** */
    const [allCategories, setAllCategories] = useState([]);
    useEffect(() => {
        const requestOne = axios.get(`/umbraco/api/projects`);
        const requestTwo = axios.get(`/umbraco/api/entities`);
        axios.all([requestOne, requestTwo]).then(
            axios.spread((...responses) => {
                // if (
                //     responses[0] &&
                //     responses[0].data[0] &&
                //     responses[0].data[0].hasOwnProperty("buildingTypes")
                // ) {
                //     setProjects(responses[0].data);
                // } else {
                //     let fakeProjects = [];
                //     responses[0].data.forEach((p) =>
                //         fakeProjects.push({
                //             ...p,
                //             buildingTypes: [
                //                 "Enebolig",
                //                 "Enebolig i rekke",
                //                 "Rekkehus",
                //                 "Tomannsbolig",
                //                 "Firemannsbolig",
                //                 "Leilighet",
                //             ],
                //         })
                //     );
                //     setProjects(fakeProjects);
                // }
                setProjects(responses[0].data);
                setVenues(responses[1].data);
                const all = [...responses[0].data, ...responses[1].data];
                let catArray = [];
                if (all) {
                    all.map(
                        (p) =>
                            p.categories !== null &&
                            p.categories.map((c) => catArray.push(c))
                    );
                }
                const seen = new Set();
                const uniqueCategories = catArray.filter((el) => {
                    const duplicate = seen.has(el.id);
                    seen.add(el.id);
                    return !duplicate;
                });
                setAllCategories(uniqueCategories);
                setProjectsAndVenues(all);
            })
        );
    }, []);

    /* ********************************** */
    /*            MEDIA QUERIES           */
    /* ********************************** */
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const updateSize = () => {
        setScreenWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", updateSize);
    }, []);

    const useStickyState = (defaultValue, key) => {
        const [value, setValue] = useState(() => {
            const stickyValue = window.localStorage.getItem(key);
            return stickyValue !== null
                ? JSON.parse(stickyValue)
                : defaultValue;
        });
        useEffect(() => {
            window.localStorage.setItem(key, JSON.stringify(value));
        }, [key, value]);
        return [value, setValue];
    };

    const [devStatus, toggleDevStatus] = useStickyState(false, "devmode");
    const [devData, setDevData] = useState(null);
    const getIcon = (title, color, size) => {
        if (title) {
            switch (title.toLowerCase()) {
                case "butikk":
                    return <FaStoreAlt size={size || "4rem"} color={color} />;
                case "lager":
                    return <FaWarehouse size={size || "4rem"} color={color} />;
                case "trening":
                    return (
                        <CustomGradientSvg
                            src={trening}
                            color={color}
                            size={size || "4rem"}
                        />
                    );
                case "tur":
                    return (
                        <CustomGradientSvg
                            src={tur}
                            color={color}
                            size={size || "4rem"}
                        />
                    );
            }
        }
    };

    const [currentPage, setCurrentPage] = useState(null);

    return (
        <UmbracoContext.Provider
            value={{
                categoryColors,
                data,
                posts,
                screenWidth,
                routeHistory,
                setRouteHistory,
                projects,
                venues,
                devStatus,
                toggleDevStatus,
                devData,
                setDevData,
                hideMap,
                setHideMap,
                coordinates,
                setCoordinates,
                getIcon,
                allCategories,
                projectsAndVenues,
                currentPageId,
                setCurrentPageId,
                currentColor,
                setCurrentColor,
                currentPage,
                setCurrentPage
            }}
        >
            {children}
        </UmbracoContext.Provider>
    );
};
