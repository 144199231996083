import React, { useContext, useEffect } from "react";

// External components
import KeyboardEventHandler from "react-keyboard-event-handler";
// import ReactJson from "react-json-view";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const DevMode = () => {
    const { devStatus, toggleDevStatus, devData } = useContext(UmbracoContext);
    useEffect(() => {
        let body = document.body;
        body.style.transition =
            "padding-left 150ms cubic-bezier(0.2, 0, 0.2, 1)";
        if (devStatus) {
            body.style.paddingLeft =
                document.getElementById("devmodecontainer").offsetWidth + "px";
            // body.style.filter = "invert(1)";
            // body.style.background = "black";
        } else {
            body.style.paddingLeft = 0;
            // body.style.background = "white";
            // body.style.filter = "invert(0)";
        }
    }, [devStatus]);
    return (
        <>
            <KeyboardEventHandler
                handleKeys={["shift + alt + d"]}
                onKeyEvent={(key, e) => toggleDevStatus(!devStatus)}
            />
            <div
                id="devmodecontainer"
                // onClick={() => toggleDevStatus(false)}
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 999,
                    minWidth: "15vw",
                    overflowY: "scroll",
                    minHeight: "100vh",
                    background: "black",
                    maxHeight: "100vh",
                    boxShadow: "0px 0px 50px rgba(0,0,0,0.25)",
                    // padding: "3rem",
                    transition: "all 0.2s ease-in-out",
                    transform: devStatus ? "" : "translateX(-200px)",
                    opacity: devStatus ? 1 : 0,
                    pointerEvents: devStatus ? "all" : "none",
                }}
            >
                {/* <ReactJson
                    src={devData}
                    theme="ashes"
                    displayDataTypes={false}
                    displayObjectSize={false}
                    indentWidth={2}
                /> */}
                <pre style={{ maxWidth: 700, overflowX: "scroll " }}>
                    {JSON.stringify(devData, null, 4)}
                </pre>
            </div>
        </>
    );
};

export default DevMode;
