import React, { useContext } from "react";

// External components
import { Button } from "react-bootstrap";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const Btn = ({ mainColor, children, className, style, onClick }) => {
    const { data } = useContext(UmbracoContext);
    return (
        <Button
            onClick={onClick}
            className={`d-flex align-items-center justify-content-between ${className} `}
            style={Object.assign(
                {
                    backgroundColor:
                        data !== null && data.colors !== null
                            ? data.colors.color4
                            : "",
                    borderColor: mainColor || "transparent",
                    color: "white",
                },
                style
            )}
        >
            {children}
        </Button>
    );
};

export default Btn;
