import React from "react";

// External components
import { SRLWrapper } from "simple-react-lightbox";
import { Row, Col } from "react-bootstrap";

const Gallery = ({ items }) => {
    const options = {
        settings: {
            slideTransitionSpeed: 0.1,
            lightboxTransitionSpeed: 0.1,
            slideSpringValues: [0, 0],
        },
    };
    return (
        <>
            {items && Array.isArray(items) && items.length && (
                <SRLWrapper options={options}>
                    <Row className="w-100">
                        {items.map((item) => (
                            <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
                                <a
                                    href={
                                        item.url +
                                        `?format=jpeg&width=1400&quality=65`
                                    }
                                    key={item.key + "link"}
                                    className="w-100 h-100 d-block"
                                >
                                    <img
                                        src={
                                            item.url +
                                            "?format=jpeg&width=800&quality=65"
                                        }
                                        alt={item.altText || item.title}
                                        style={{ maxHeight: 500 }}
                                        key={item.key + "image"}
                                        className="w-100 h-100 object-fit-cover hover-scale rounded"
                                    />
                                </a>
                            </Col>
                        ))}
                    </Row>
                </SRLWrapper>
            )}
            {/* {items && Array.isArray(items) && items.length && items.map(item =>)} */}
        </>
    );
};

export default Gallery;
