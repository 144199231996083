import React, { useContext, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// Internal components
// import Cube from "components/Cube";
import AnimateMount from "components/AnimateMount";
import SinglePost from "components/SinglePost";
import BodyContent from "components/BodyContent";
import Btn from "components/Btn";
import InternalOrExternalLink from "components/InternalOrExternalLink";

// External components
// import TypeWriterEffect from "react-typewriter-effect";
import { Container, Row, Col } from "react-bootstrap";
// import { usePalette } from "react-palette";
import { motion } from "framer-motion";
import StatusSlider from "components/StatusSlider";
import BuildingTypes from "components/BuildingTypes";

const Project = () => {
    const { slug } = useParams();
    const location = useLocation();
    const { projects, setDevData, devData, getIcon, data } =
        useContext(UmbracoContext);
    const [currentProject, setCurrentProject] = useState(null);
    // const [imgPalette, setImgPalette] = useState("");
    // const { data } = usePalette(imgPalette);

    useEffect(() => {
        if (currentProject === null && projects !== null) {
            let current = projects.filter(
                (p) => p.url.toLowerCase() === location.pathname
            );
            setCurrentProject(current[0]);
            // if (
            //     current[0] !== null &&
            //     current[0] !== undefined &&
            //     current[0].featuredImage
            // ) {
            //     setImgPalette(current[0].featuredImage.url);
            // }
        }
    }, [currentProject, slug, setCurrentProject, projects, location.pathname]);
    useEffect(() => {
        if (devData !== currentProject) {
            setDevData(currentProject);
        }
        // console.log(currentProject);
    }, [currentProject, setDevData, devData]);

    const [currentPage, setCurrentPage] = useState(null);
    useEffect(() => {
        if (data.children && currentProject && currentProject.title) {
            let pagesWithProjectTitles = [];
            let pagesWithProjects = data.children.filter(
                (page) => page.projects !== null && page.projects !== undefined
            );
            pagesWithProjects.forEach((page) => {
                let pTitles = [];
                page.projects.forEach((project) => pTitles.push(project.title));
                pagesWithProjectTitles.push({ ...page, pTitles: pTitles });
            });
            let cPage = pagesWithProjectTitles.filter((page) =>
                page.pTitles.includes(currentProject.title)
            )[0];
            setCurrentPage(cPage);
        }
    }, [data, currentProject]);
    let color = "#fff";
    return (
        <>
            {currentProject !== null && currentProject !== undefined && (
                <>
                    <Helmet>
                        <title>
                            {currentProject.seo.title || currentProject.title}
                        </title>

                        <meta
                            property="og:title"
                            content={
                                currentProject.seo.title || currentProject.title
                            }
                        />
                        <meta
                            name="twitter:title"
                            content={
                                currentProject.seo.title || currentProject.title
                            }
                        />

                        <meta
                            property="og:image"
                            content={
                                currentProject.seo.featuredImage.url ||
                                currentProject.featuredImage.url
                            }
                        />
                        <meta
                            name="twitter:image"
                            content={
                                currentProject.seo.featuredImage.url ||
                                currentProject.featuredImage.url
                            }
                        />
                        <meta
                            property="og:description"
                            content={currentProject.seo.description}
                        />
                        <meta
                            name="twitter:description"
                            content={currentProject.seo.description}
                        />
                        <meta
                            name="description"
                            content={currentProject.seo.description}
                        />
                    </Helmet>
                    <AnimateMount trigger>
                        <Container>
                            <div className="no-container-interaction above-cubes ">
                                <div className="above-cubes">
                                    <Row>
                                        <Col xs={12} lg={6} className="px-4">
                                            {currentProject.title && (
                                                <h1>{currentProject.title}</h1>
                                                // <h1 style={{ minHeight: 170 }}>
                                                //     <TypeWriterEffect
                                                //         text={currentProject.title.substring(
                                                //             0,
                                                //             24
                                                //         )}
                                                //         hideCursorAfterText={true}
                                                //         // multiTextDelay={2000}
                                                //         cursorColor="#fff"
                                                //         typeSpeed={50}
                                                //     />
                                                // </h1>
                                            )}
                                            <Row className="w-100 d-flex pt-3 project-info mx-0">
                                                {/* <pre>
                                                    {JSON.stringify(
                                                        currentProject,
                                                        null,
                                                        1
                                                    )}
                                                </pre> */}
                                                {currentProject.address && (
                                                    <Col
                                                        xs="auto"
                                                        className="project-info__item"
                                                    >
                                                        <p>Adresse:</p>
                                                        <p className="h4">
                                                            {
                                                                currentProject.address
                                                            }
                                                        </p>
                                                    </Col>
                                                )}
                                                <Col
                                                    xs="auto"
                                                    className="project-info__item"
                                                >
                                                    <p>Prosjekteiere:</p>
                                                    {currentProject.owningEntities ? (
                                                        currentProject.owningEntities.map(
                                                            (owner) =>
                                                                owner.url ? (
                                                                    <a
                                                                        className="h4"
                                                                        href={
                                                                            owner.url
                                                                        }
                                                                        rel="noreferrer"
                                                                    >
                                                                        {
                                                                            owner.title
                                                                        }
                                                                    </a>
                                                                ) : (
                                                                    owner.title
                                                                )
                                                        )
                                                    ) : (
                                                        <p>-</p>
                                                    )}
                                                </Col>
                                                {currentProject.categories && (
                                                    <Col
                                                        xs="auto"
                                                        className="project-info__item d-flex"
                                                        style={{
                                                            fexWrap: "wrap",
                                                            gap: "1rem",
                                                        }}
                                                    >
                                                        <p>Kategorier:</p>
                                                        {currentProject.categories.map(
                                                            (c) => (
                                                                <InternalOrExternalLink
                                                                    to={c.url}
                                                                    className="d-flex flex-column align-items-center"
                                                                >
                                                                    <Btn
                                                                        className="btn-lg mb-2"
                                                                        key={
                                                                            "btn-" +
                                                                            c.key
                                                                        }
                                                                    >
                                                                        {getIcon(
                                                                            c.title,
                                                                            "white"
                                                                        )}
                                                                    </Btn>
                                                                    {c.title}
                                                                </InternalOrExternalLink>
                                                            )
                                                        )}
                                                    </Col>
                                                )}
                                                {currentPage &&
                                                currentPage.slogan ? (
                                                    <Col
                                                        md={5}
                                                        className="project-info__item ml-auto"
                                                    >
                                                        <div
                                                            className="h3 text-uppercase text-right d-inline font-weight-bold"
                                                            style={{
                                                                color: color,
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: currentPage.slogan,
                                                            }}
                                                        ></div>
                                                    </Col>
                                                ) : (
                                                    ""
                                                )}
                                            </Row>
                                            {currentProject.introText && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: currentProject.introText,
                                                    }}
                                                    className="my-5"
                                                ></div>
                                            )}
                                        </Col>
                                        <Col xs={12} lg={6} className="px-4 ">
                                            <motion.div
                                                initial={{
                                                    opacity: 0,
                                                    translateX: 100,
                                                }}
                                                animate={{
                                                    opacity: 1,
                                                    translateX: 0,
                                                }}
                                                exit={{
                                                    opacity: 0,
                                                    translateX: 100,
                                                }}
                                            >
                                                {currentProject.featuredImage !==
                                                    null && (
                                                    <img
                                                        src={
                                                            currentProject
                                                                .featuredImage
                                                                .url +
                                                            "?format=jpeg&width=1200&quality=65"
                                                        }
                                                        alt={
                                                            currentProject
                                                                .featuredImage
                                                                .altText ||
                                                            currentProject
                                                                .featuredImage
                                                                .title
                                                        }
                                                        style={{
                                                            maxWidth: 800,
                                                        }}
                                                        className="w-100 h100 rounded d-block my-5 project-info__image"
                                                    />
                                                )}
                                                {currentProject.status ? (
                                                    <StatusSlider
                                                        status={
                                                            currentProject.status
                                                        }
                                                        start={
                                                            currentProject.startDate
                                                        }
                                                        end={
                                                            currentProject.completionDate
                                                        }
                                                        color={color}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                                {currentProject.buildingTypes ? (
                                                    <Row
                                                        style={{ gap: "2rem" }}
                                                        className="mx-0"
                                                    >
                                                        {currentProject.buildingTypes && (
                                                            <BuildingTypes
                                                                types={
                                                                    currentProject.buildingTypes
                                                                }
                                                                color={color}
                                                            />
                                                        )}
                                                    </Row>
                                                ) : (
                                                    ""
                                                )}
                                            </motion.div>
                                        </Col>
                                    </Row>
                                    <BodyContent
                                        content={currentProject.bodyContent}
                                    />
                                    {currentProject.childEntities !== null &&
                                        Array.isArray(
                                            currentProject.childEntities
                                        ) &&
                                        currentProject.childEntities.length >
                                            0 && (
                                            <>
                                                <h1>Steder</h1>
                                                <div className="posts-grid">
                                                    {currentProject.childEntities.map(
                                                        (post, index) =>
                                                            post && (
                                                                <SinglePost
                                                                    // published={post.publishDate}
                                                                    thumbnailSrc={
                                                                        post
                                                                            .featuredImage
                                                                            ?.url
                                                                    }
                                                                    thumbnailAltText={
                                                                        post
                                                                            .featuredImage
                                                                            ?.altText ||
                                                                        post
                                                                            .featuredImage
                                                                            ?.title
                                                                    }
                                                                    title={
                                                                        post.title
                                                                    }
                                                                    // text={post.introText}
                                                                    // limitText={
                                                                    //     !current && index === 0
                                                                    //         ? false
                                                                    //         : true
                                                                    // }
                                                                    // textColor={
                                                                    //     data !== null &&
                                                                    //     data.colors.color2
                                                                    // }
                                                                    // bg={
                                                                    //     data !== null &&
                                                                    //     data.colors.bg
                                                                    // }
                                                                    // featured={
                                                                    //     !current && index === 0
                                                                    //         ? true
                                                                    //         : false
                                                                    // }
                                                                    slug={
                                                                        post.url
                                                                    }
                                                                    // mainColor={
                                                                    //     data !== null &&
                                                                    //     data.indexPages
                                                                    //         .filter(
                                                                    //             (p) =>
                                                                    //                 p.title
                                                                    //                     .toLowerCase()
                                                                    //                     .replace(
                                                                    //                         " ",
                                                                    //                         "_"
                                                                    //                     ) ===
                                                                    //                 post.category
                                                                    //         )
                                                                    //         .map(
                                                                    //             (item) =>
                                                                    //                 item.mainColor
                                                                    //         )
                                                                    // }
                                                                    key={
                                                                        post.key +
                                                                        "single-post"
                                                                    }
                                                                    // id={post.key || post.id}
                                                                />
                                                            )
                                                    )}
                                                </div>
                                            </>
                                        )}
                                </div>
                            </div>
                        </Container>
                    </AnimateMount>
                </>
            )}
        </>
    );
};

export default Project;
