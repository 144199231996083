import React from "react";
import Lottie from "react-lottie";
import animationData from "./logodata-v2.json";

const Logo = ({ width }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <div className="logo text-center">
            <Lottie options={defaultOptions} height="auto" width={width} />
            <p
                className="h5"
                style={{ color: "rgba(255,255,255,0.7)", marginTop: "-0.5rem" }}
            >
                Nært. Alt.
            </p>
        </div>
    );
};

export default Logo;
