

import Hotkeys from "react-hot-keys";

const AllHotkeys = () => {
    return (
        <div>
            <Hotkeys
                keyName="alt+shift+u"
                allowRepeat={true}
                onKeyDown={() => {
                    window
                        .open(
                            `https://lundsentrum.no/umbraco`,
                            "_blank"
                        )
                        .focus();
                }
                }
            />
            <Hotkeys
                keyName="alt+shift+g"
                allowRepeat={true}
                onKeyDown={() =>
                    window
                        .open(
                            `https://github.com/aksellsor/07_react_lund-sentrum`,
                            "_blank"
                        )
                        .focus()
                }
            />
        </div>
    );
};

export default AllHotkeys;