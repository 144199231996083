import React, { useEffect, useState, useContext } from "react";
// import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// External components
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

// Internal components
import Categories from "components/Categories";
import Letter from "components/Letter";
import Projects from "components/Projects";
import Posts from "components/Posts";
// import BreadCrumbs from "../components/BreadCrumbs";
import DiagonalBox from "components/DiagonalBox";
import Btn from "components/Btn";
import InternalOrExternalLink from "components/InternalOrExternalLink";
import Loading from "react-loading-animation";
import AnimateMount from "components/AnimateMount";

const DynamicPage = ({
    title,
    letter,
    mainColor,
    secondaryColor,
    id,
    introText,
    bodyText,
    seo,
    getUnique,
    slug,
}) => {
    // const location = useLocation();
    const {
        data,
        getIcon,
        setCurrentPageId,
        setCurrentColor,
        currentColor,
        currentPageId,
    } = useContext(UmbracoContext);
    const [categories, setCategories] = useState(null);
    const [projects, setProjects] = useState(null);
    const [venues, setVenues] = useState(null);
    if (mainColor && currentColor !== mainColor) {
        setCurrentColor(mainColor);
    }
    if (id && currentPageId !== id) {
        setCurrentPageId(id);
    }
    useEffect(() => {
        if (id) {
            const requestOne = axios.get(`/umbraco/api/pages/${id}/projects`);
            const requestTwo = axios.get(`/umbraco/api/pages/${id}/entities`);
            axios
                .all([requestOne, requestTwo])
                .then(
                    axios.spread((...responses) => {
                        // const all = [
                        //     ...responses[0].data,
                        //     ...responses[1].data,
                        // ];
                        setProjects((p) => [...responses[0].data]);
                        setVenues((p) => [...responses[1].data]);
                        let allPageCategories = [];
                        responses[0].data.map(
                            (d) =>
                                d.categories !== null &&
                                d.categories.map((c) =>
                                    allPageCategories.push(c)
                                )
                        );
                        responses[1].data.map(
                            (d) =>
                                d.categories !== null &&
                                d.categories.map((c) =>
                                    allPageCategories.push(c)
                                )
                        );
                        const seen = new Set();
                        const uniqueCategories = allPageCategories.filter(
                            (el) => {
                                const duplicate = seen.has(el.id);
                                seen.add(el.id);
                                return !duplicate;
                            }
                        );
                        setCategories([...uniqueCategories]);
                    })
                )
                .catch((errors) => {
                    console.error(errors);
                });
        }
    }, [id]);
    return (
        <div className={id !== 1090 ? "p-0" : ""}>
            {id.toString() === "1090" && (
                <>
                    <div className="dynamic-page__container--infopage">
                        <Container className="dynamic-page__container">
                            <Row className="d-none d-lg-block dynamic-page-categories">
                                <span className="mb-5 text-left d-inline-block">
                                    <Categories simple small={true} />
                                </span>
                            </Row>
                        </Container>
                    </div>
                </>
            )}
            <>
                {/* <h1>{currentPageId}</h1> */}
                <Helmet>
                    <title>
                        {data.baseName + " - " + (seo.title || title)}
                    </title>
                    <meta
                        property="og:title"
                        content={data.baseName + " - " + (seo.title || title)}
                    />
                    <meta
                        name="twitter:title"
                        content={data.baseName + " - " + (seo.title || title)}
                    />
                    <meta property="og:description" content={seo.description} />
                    <meta
                        name="twitter:description"
                        content={seo.description}
                    />
                    <meta name="description" content={seo.description} />
                    {seo.featuredImage && (
                        <meta
                            property="og:image"
                            content={seo.featuredImage.url}
                        />
                    )}
                    {seo.featuredImage && (
                        <meta
                            name="twitter:image"
                            content={seo.featuredImage.url}
                        />
                    )}
                </Helmet>
                {/* <Map home={location.pathname === "/" ? true : false} /> */}
                {/* IF Nært Alt page */}
                {id.toString() === "1090" && (
                    <AnimateMount trigger>
                        <>
                            <DiagonalBox
                                className="infopage__diagonal-box-header"
                                title={title.replace(/[.]/g, ".<br/>")}
                                bgColor={mainColor}
                                color={secondaryColor}
                            // introText={introText}
                            // bodyText={bodyText}
                            />
                            <Container>
                                <Posts limit={5} />
                            </Container>
                        </>
                    </AnimateMount>
                )}
                {/* IF NOT nært alt page */}
                {id.toString() !== "1090" && (
                    <>
                        <Container className="dynamic-page__container">
                            <Row className="d-none d-lg-block dynamic-page-categories">
                                <span className="mb-5 text-left d-inline-block">
                                    <Categories simple small={true} />
                                </span>
                            </Row>
                            <AnimateMount trigger>
                                <Row className="mx-auto">
                                    {/* {location && (
                                                <h1
                                                    className="w-100 text-capitalize"
                                                    style={{ color: mainColor }}
                                                >
                                                    {location.pathname
                                                        .replace(/\//g, "")
                                                        .replace("-", " ")
                                                        .replace("ae", "æ")
                                                        .replace("oe", "ø")
                                                        .replace("aa", "å")}
                                                </h1>
                                            )} */}
                                    <Col
                                        xs={12}
                                        md={12}
                                        lg={6}
                                        className="no-container-interaction px-4 px-md-0"
                                    >
                                        <Row>
                                            <div className="dynamic-left-section">
                                                {/* <div className="pb-5">
                                                                    <BreadCrumbs
                                                                        crumbs={[
                                                                            {
                                                                                to: location.pathname,
                                                                                title: location.pathname
                                                                                    .replace(/\//g, "")
                                                                                    .replace("-", " ")
                                                                                    .replace("ae", "æ")
                                                                                    .replace("oe", "ø")
                                                                                    .replace("aa", "å"),
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div> */}
                                                {letter !== null &&
                                                    letter.type === "svg" && (
                                                        <Letter
                                                            title={title}
                                                            url={letter.url}
                                                            mainColor={mainColor}
                                                        />
                                                    )}
                                            </div>
                                        </Row>

                                        {categories && categories.length > 0 && (
                                            <Row
                                                style={{
                                                    gap: "1rem",
                                                    marginBottom: 50,
                                                }}
                                                className="px-md-5"
                                            >
                                                <Col xs={12}>
                                                    <div>Kategorier</div>
                                                </Col>
                                                {categories.map((c) => (
                                                    <InternalOrExternalLink
                                                        to={c.url}
                                                        className="d-flex flex-column align-items-center"
                                                    >
                                                        <Btn
                                                            className="btn-lg mb-2 btn--transparent"
                                                            key={"btn-" + c.key}
                                                        >
                                                            {getIcon(
                                                                c.title,
                                                                mainColor
                                                            )}
                                                        </Btn>
                                                        {c.title}
                                                    </InternalOrExternalLink>
                                                ))}
                                            </Row>
                                        )}
                                        <Row>
                                            <Col className="mb-5 px-md-5">
                                                {introText && (
                                                    <div
                                                        className="h2 above-cubes paragraph"
                                                        dangerouslySetInnerHTML={{
                                                            __html: introText,
                                                        }}
                                                    ></div>
                                                )}
                                                {bodyText && (
                                                    <div
                                                        className="above-cubes paragraph"
                                                        style={{
                                                            color: data.colors
                                                                .color2,
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: bodyText,
                                                        }}
                                                    ></div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>

                                    {/* Projects */}
                                    <Col xs={12} lg={6} className="mb-5">
                                        <div className="dynamic-right-section">
                                            {venues || projects ? (
                                                <>
                                                    {Array.isArray(projects) &&
                                                        projects.length ? (
                                                        <Projects
                                                            items={projects}
                                                            mainColor={mainColor}
                                                            categorized={true}
                                                            id={id}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {Array.isArray(venues) &&
                                                        venues.length ? (
                                                        <Projects
                                                            items={venues}
                                                            mainColor={mainColor}
                                                            id={id}
                                                            categorized={true}
                                                            isLev={true}
                                                        // prefix="steder"
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            ) : (
                                                <Loading />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                {id.toString() !== "1088" && (
                                    <Row className="d-none d-lg-block ">
                                        <Categories simple horizontal />
                                    </Row>
                                )}
                                <Row className="mt-5">
                                    {id.toString() !== "1088" && (
                                        <Posts limit={5} />
                                    )}
                                </Row>
                            </AnimateMount>
                        </Container>
                    </>
                )}


            </>
        </div>
    );
};

export default DynamicPage;
