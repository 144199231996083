import React, { useContext, useEffect, useState } from "react";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// External components
import { Row, Col } from "react-bootstrap";
import ReactVivus from "react-vivus";

const Letter = ({ url, title, mainColor }) => {
    const { data } = useContext(UmbracoContext);
    const [ready, setReady] = useState(false);
    useEffect(() => {
        if (
            // document.querySelectorAll("#letter svg linearGradient > stop") !==
            //     null &&
            mainColor &&
            ready
        ) {
            document
                .querySelectorAll("#letter-vivus svg linearGradient > stop")
                .forEach((el) => el.setAttribute("stop-color", mainColor));
        }
    }, [mainColor, ready]);
    return (
        <Row className="mb-5 px-sm-5 w-100 letter align-items-center">
            {url && (
                <Col xs={4} md={5} lg={4} xl={4} className="mb-5">
                    <ReactVivus
                        id="letter-vivus"
                        option={{
                            file: url,
                            duration: 60,
                            start: "autostart",
                            // delay: 59,
                            type: "oneByOne",
                            onReady: () => {
                                setReady(true);
                            },
                        }}
                        className="above-cubes no-interaction w-100"
                    />
                </Col>
            )}
            {title && (
                <Col xs={8} md={7} lg={8} xl={8}>
                    <h1 className="low-line-height above-cubes no-interaction ml-4 ml-sm-5">
                        <span
                            style={{
                                color: data !== null ? data.colors.color3 : "",
                            }}
                        >
                            lund
                            <br />
                            sentrum
                            <br />
                        </span>
                        <span
                            className="mt-3 d-inline-block"
                            style={{
                                color: data !== null ? data.colors.color1 : "",
                            }}
                        >
                            {title}
                        </span>
                    </h1>
                </Col>
            )}
        </Row>
    );
};

export default Letter;
