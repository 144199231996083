import React, { useCallback, useContext, useEffect } from "react";
import { Route, useLocation, Switch } from "react-router-dom";

// Import routes
import Home from "routes/Home";
import Article from "routes/Article";
import Articles from "routes/Articles";
import DynamicPage from "routes/DynamicPage";
import Project from "routes/Project";
import Venue from "routes/Venue";
// import NotFound from "routes/NotFound";
import Contact from "routes/Contact";
import Category from "routes/Category";
import Categories from "routes/Categories";

// Internal components
// import AnimateMount from "components/AnimateMount";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const Routes = ({ key }) => {
    const { pathname } = useLocation();
    const {
        data,
        setRouteHistory,
        setHideMap,
        setCurrentColor,
        venues,
        setCurrentPage
    } = useContext(UmbracoContext);

    const updateRouteHistory = useCallback(
        (val) => {
            setRouteHistory(h => [...h, val]);
        },
        [setRouteHistory],
    );
    const updateHideMap = useCallback(
        (val) => {
            setHideMap(val);
        },
        [setHideMap],
    );

    useEffect(() => {
        if (pathname) {
            updateRouteHistory(pathname);
            if (pathname.includes("naert-alt")) {
                updateHideMap(true);
            } else {
                updateHideMap(false);
            }
        }
    }, [pathname, updateRouteHistory, updateHideMap]);
    const updateCurrentColor = useCallback(
        (val) => {
            setCurrentColor(val);
        },
        [setCurrentColor],
    );
    useEffect(() => {
        let slugs = [];
        data?.indexPages.forEach((p) => slugs.push(p.slug));
        // console.log(!slugs.includes(pathname));
        if (!slugs.includes(pathname) && !pathname.includes("prosjekter")) {
            updateCurrentColor(null);
        }
        // if (!slugs.includes(pathname)) {
        //     setCurrentColor(null);
        // }
    }, [data, pathname, updateCurrentColor]);
    const updateCurrentPage = useCallback(
        (val) => {
            setCurrentPage(val);
        },
        [setCurrentPage],
    );

    useEffect(() => {
        let current = data?.indexPages?.find((p) => p.slug === pathname);
        updateCurrentPage(current);
    }, [data, pathname, updateCurrentPage]);
    return (
        <Switch location={window.location} key={window.location.pathname}>
            {data !== null &&
                Array.isArray(data.indexPages) &&
                data.indexPages.map((d) => (
                    <Route path={d.slug} exact key={d.key + "route"}>
                        <DynamicPage
                            title={d.title || ""}
                            letter={d.letter}
                            mainColor={d.mainColor || ""}
                            secondaryColor={d.secondaryColor || ""}
                            id={d.id}
                            slug={d.slug}
                            key={d.key + "div"}
                            introText={d.introText || ""}
                            bodyText={d.bodyText || ""}
                            seo={d.seo}
                        />
                    </Route>
                ))}
            <Route path="/aktuelt/" component={Articles} exact key="aktuelt" />
            {/* {data !== null &&
                Array.isArray(data.children) &&
                data.children.map(
                    (d) =>
                        d.id === 1442 && (
                            <Route
                                path="/kontakt/"
                                component={Contact}
                                exact
                                key="kontakt"
                            />
                        )
                )} */}
            <Route path="/kontakt/" component={Contact} exact key="kontakt" />
            <Route
                path="/kategorier/:slug"
                component={Category}
                exact
                key="kategorier/article"
            />
            <Route
                path="/kategorier"
                component={Categories}
                exact
                key="kategorier"
            />
            <Route
                path="/aktuelt/:slug"
                component={Article}
                exact
                key="aktuelt/article"
            />
            <Route
                path="/prosjekter/:slug"
                component={Project}
                exact
                key="prosjekter/project"
            />
            <Route
                path={`/${venues && venues[0]?.url.split("/")[1]}/:slug`}
                exact
                key="lev/sted"
            >
                <Venue />
            </Route>
            <Route path="/" component={Home} exact key="home" />
            {/* <Route key="404">
                <AnimateMount trigger delay={3}>
                    <NotFound />
                </AnimateMount>
            </Route> */}
        </Switch>
    );
};

export default Routes;
